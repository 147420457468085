import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import { current_date } from "@/utils/currentDate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    manageHeaderTitleFTitle: '医学会议管理',
    manageHeaderTitleSTitle: '九宫格会议管理',
    streamId:null,
    isShowNav:true, //是否显示导航栏
    isShowLink:false, //是否显示链接弹窗
    linkList:{
      //前端观看链接
      targetUrl:null,
      //服务器链接
      obsPushIp:null,
      //串流密钥链接
      obsPushName:null,
      //rtmp链接
      pullRtmpIp:null,
      //flv链接
      pullFlvIp:null,
      //m3u8链接
      pullM3u8Ip:null,
    },
    //功能模块id
    funId:null,
    //功能列表内容
    funList:[],
    scrollEl:null,
    //登录初始状态为1
    loginShow:1,
    //登录信息
    userInfoList:{},
    meetCode:null,
    isShowIconTitle:0,
    isOpenCountdown:0,
    statisticsLiveCreated:'',
    isShowIssueBtn:false, //订单是否支付
    kefuImg:'', //客服二维码

    //是否打开消息提示弹窗
    enableMessagePrompt:false,
    notReadAllCountXT:0, //获取系统公告未读条数

    //是否打开免费领取代搭建会议
    isOpenFreeSetMeeting:false,

    //是否打开代金卷领取弹窗
    isOpenVoucherModel:false,


    //会务会议详情
    liveInfoList:{},




    returnId: '',
    condition: '',
    //  通过的审批按钮
    passBtn: false,
    //  审核的模板链接
    caseLink: '',
    //图片打码左侧id
    returnLeftId: '',
    //图片打码状态
    picState: '',
    //  直播抽奖活动标题
    liveTitle: '',
    //  直播活动会议名称
    liveMeetName: '',
    //  直播间新增里的两张图片默认打开显示会议的图片
    liveImg: {},
    //  医学会议直播间新增里的两张图片默认打开显示会议的图片
    convertionImg: {},
    // 病例审核页码数
    pageNo: 1,

    //职称考试分类名称Id
    examId: null,

    //  名单对话框的数据  注册会员管理-病历项目管理中的资格证上传-
    listDialogBox: {
      //  是否开启对话框
      isShow: false,
      //  用户memberId
      memberId: null,
      //  对话框中的数据
      addForm: {},
    },
    //  liveMeet  直播会议的操作记录  --历史记录
    liveMeetHistory: {
      year: current_date(),
      //判断路径上进入
      isRouter: true,
      state: '',
      title: '',
      code: '',
      principal: '',
      pageNo: 1,
    },

    // 数据分析需要传的id
    dataAnalysisId:''
  },
  mutations: {
    dataAnalysisId(state,data){
      state.dataAnalysisId = data
    },
    manageHeaderTitleFTitle(state, value) {
      state.manageHeaderTitleFTitle = value
    },
    manageHeaderTitleSTitle(state, value) {
      state.manageHeaderTitleSTitle = value
    },
    isShowNav(state, value) {
      state.isShowNav = value
    },
    streamId(state, value) {
      state.streamId = value
    },
    isShowLink(state, value) {
      state.isShowLink = value
    },
    linkList(state, value) {
      state.linkList = value
    },
    funId(state, value) {
      state.funId = value
    },
    funList(state, value) {
      state.funList = value
    },
    scrollEl(state, value) {
      state.scrollEl = value
    },
    loginShow(state, value) {
      state.loginShow = value
    },
    userInfoList(state, value) {
      state.userInfoList = value
    },
    meetCode(state, value) {
      state.meetCode = value
    },
    isShowIconTitle(state, value) {
      state.isShowIconTitle = value
    },
    isOpenCountdown(state, value) {
      state.isOpenCountdown = value
    },
    statisticsLiveCreated(state, value) {
      state.statisticsLiveCreated = value
    },
    isShowIssueBtn(state, value) {
      state.isShowIssueBtn = value
    },
    kefuImg(state, value) {
      state.kefuImg = value
    },
    enableMessagePrompt(state, value) {
      state.enableMessagePrompt = value
    },
    isOpenFreeSetMeeting(state, value) {
      state.isOpenFreeSetMeeting = value
    },
    isOpenVoucherModel(state, value) {
      state.isOpenVoucherModel = value
    },
    liveInfoList(state, value) {
      state.liveInfoList = value
    },
    notReadAllCountXT(state, value) {
      state.notReadAllCountXT = value
    },
    //病例返回上一层恢复历史状态
    returnId(state, data) {
      state.returnId = data
    },
    condition(state, data) {
      state.condition = data
    },
    passBtn(state, data) {
      state.passBtn = data
    },
    caseLink(state, data) {
      state.caseLink = data
    },
    //图片打码返回上一层恢复历史状态
    returnLeftId(state, data) {
      state.returnLeftId = data
    },
    picState(state, data) {
      state.picState = data
    },
    liveMeetName(state, data) {
      state.liveMeetName = data
    },
    liveTitle(state, data) {
      state.liveTitle = data
    },
    liveImg(state, data) {
      state.liveImg = data
    },
    convertionImg(state, data) {
      state.convertionImg = data
    },
    pageNo(state, data) {
      state.pageNo = data
    },
    listDialogBox(state, data) {
      state.listDialogBox = data
    },
    liveMeetHistory(state, data) {
      state.liveMeetHistory = data
    },
  },
  actions: {
    manageHeaderTitleFTitle(context, value) {
      context.commit("manageHeaderTitleFTitle", value)
    },
    manageHeaderTitleSTitle(context, value) {
      context.commit("manageHeaderTitleSTitle", value)
    },
    isShowNav(context, value) {
      context.commit("isShowNav", value)
    },
    streamId(context, value) {
      context.commit("streamId", value)
    },
    isShowLink(context, value) {
      context.commit("isShowLink", value)
    },
    linkList(context, value) {
      context.commit("linkList", value)
    },
    funId(context, value) {
      context.commit("funId", value)
    },
    funList(context, value) {
      context.commit("funList", value)
    },
    scrollEl(context, value) {
      context.commit("scrollEl", value)
    },
    loginShow(context, value) {
      context.commit("loginShow", value)
    },
    userInfoList(context, value) {
      context.commit("userInfoList", value)
    },
    meetCode(context, value) {
      context.commit("meetCode", value)
    },
    isShowIconTitle(context, value) {
      context.commit("isShowIconTitle", value)
    },
    isOpenCountdown(context, value) {
      context.commit("isOpenCountdown", value)
    },
    statisticsLiveCreated(context, value) {
      context.commit("statisticsLiveCreated", value)
    },
    isShowIssueBtn(context, value) {
      context.commit("isShowIssueBtn", value)
    },
    kefuImg(context, value) {
      context.commit("kefuImg", value)
    },
    enableMessagePrompt(context, value) {
      context.commit("enableMessagePrompt", value)
    },
    isOpenFreeSetMeeting(context, value) {
      context.commit("isOpenFreeSetMeeting", value)
    },
    isOpenVoucherModel(context, value) {
      context.commit("isOpenVoucherModel", value)
    },
    liveInfoList(context, value) {
      context.commit("liveInfoList", value)
    },
    notReadAllCountXT(context, value) {
      context.commit("notReadAllCountXT", value)
    },
    returnId(context, data) {
      context.commit('returnId', data)
    },
    condition(context, data) {
      context.commit('condition', data)
    },
    passBtn(context, data) {
      context.commit('passBtn', data)
    },
    caseLink(context, data) {
      context.commit('caseLink', data)
    },
    returnLeftId(context, data) {
      context.commit('returnLeftId', data)
    },
    picState(context, data) {
      context.commit('picState', data)
    },
    liveTitle(context, data) {
      context.commit('liveTitle', data)
    },
    liveMeetName(context, data) {
      context.commit('liveMeetName', data)
    },
    liveImg(context, data) {
      context.commit('liveImg', data)
    },
    convertionImg(context, data) {
      context.commit('convertionImg', data)
    },
    pageNo(context, data) {
      context.commit('pageNo', data)
    },
    listDialogBox(context, data) {
      context.commit('listDialogBox', data)
    },
    liveMeetHistory(context, data) {
      context.commit('liveMeetHistory', data)
    },

  },
  modules: {
  },
  //配置插件
  plugins:[
    createPersistedState({
      storage:sessionStorage //指明存储在sessionStorage中
    })
  ]

})
