<template>
  <div class="footer">
    <div style="display: flex; justify-content: space-between">
      <div class="title"></div>
<!--      &lt;!&ndash;   管理员   &ndash;&gt;-->
<!--      <div v-if="user.isStaff === 2" class="info">-->
<!--        &lt;!&ndash; <a-icon style="margin-top: 27px;font-size: 25px;" type="bell" /> &ndash;&gt;-->
<!--        <a-popover-->
<!--          v-model="visibleUser"-->
<!--          trigger="click"-->
<!--          placement="bottomRight"-->
<!--        >-->
<!--          <span slot="title">-->
<!--            {{ user.name }}-->
<!--          </span>-->
<!--          <div slot="content">-->
<!--            <div-->
<!--              style="-->
<!--                height: 30px;-->
<!--                line-height: 30px;-->
<!--                color: #5a5a5a;-->
<!--                cursor: pointer;-->
<!--              "-->
<!--              @click="log_out"-->
<!--            >-->
<!--              <a-icon type="logout" /> 退出登录-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--            @click="openPopoverUser"-->
<!--            style="background-color: #1890ff"-->
<!--            class="headerSty"-->
<!--          >-->
<!--            {{ userNameSlice }}-->
<!--          </div>-->
<!--        </a-popover>-->
<!--        <div-->
<!--          @click="openPopoverUser"-->
<!--          style="margin-top: 7px; margin-left: 5px; margin-right: 20px"-->
<!--        >-->
<!--          <a-icon style="font-size: 12px" type="caret-down" />-->
<!--        </div>-->
<!--      </div>-->
      <!--   普通商户   -->
<!--      <div v-else class="info">-->
      <div class="info">
        <div class="messageNat">
          <div class="systemNotice" @click="openMessageNotifi('DJJ')">
              <admin_con style="font-size: 30px;margin-right: 35px" type="icon-liwu"/>
          </div>


          <div class="systemNotice" @click="openMessageNotifi('XTTZ')">
            <div class="icon">
              <a-icon style="font-size: 30px;" type="mail" />
              <div v-if="notReadAllCountXT" class="numberIcon">{{notReadAllCountXT}}</div>
            </div>
            <div style="color: #333333;margin-left: 10px;">系统公告<span v-if="notReadAllCountXT">({{notReadAllCountXT}})</span></div>
          </div>
<!--          <div class="messageNotification">-->
<!--            <div class="icon">-->
<!--              <a-icon style="font-size: 25px;" type="bell" />-->
<!--              <div class="numberIcon">9</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <a-popover v-model="visible" trigger="click" placement="bottomRight">
          <span slot="title">
            {{
              userInfoList.memberStatus === 2
                ? userInfoList.companyName
                : userInfoList.contactsName
            }}
          </span>
          <div slot="content">
            <div style="height: 100px; color: #5a5a5a">
              <!--              0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核    -->
              <div style="cursor: pointer" @click="openRZ(userInfoList)">
                <a-icon type="safety" /> 实名认证（
                <span
                  :style="{
                    color:
                      userInfoList.companyStatus === 0
                        ? ''
                        : userInfoList.companyStatus === 1
                        ? '#45a5e6'
                        : userInfoList.companyStatus === 2
                        ? 'red'
                        : userInfoList.companyStatus === 3 || userInfoList.companyStatus === 4 ? '#35D57E':'',
                  }"
                >
                  {{
                    userInfoList.companyStatus === 0
                      ? "未认证"
                      : userInfoList.companyStatus === 1
                      ? "待审核"
                      : userInfoList.companyStatus === 2
                      ? "未通过"
                      : userInfoList.companyStatus === 3 || userInfoList.companyStatus === 4
                      ? "已认证":''
                  }} </span
                >）
              </div>
              <div @click="linkBtn" style="margin-top: 10px; cursor: pointer">
                <a-icon style="font-size: 14px" type="user" /> 账号信息
              </div>
            </div>
            <div
              style="
                height: 30px;
                line-height: 30px;
                color: #5a5a5a;
                cursor: pointer;">
              <a-popconfirm
                  title="是否确认退出登录?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="log_out"
              >
                <a-icon type="logout" /> 退出登录
              </a-popconfirm>
<!--              <a-icon type="logout" /> 退出登录-->
            </div>
          </div>
          <div
            @click="openPopover"
            :style="{
              backgroundColor:userInfoList.memberStatus === 0 ? '#b1afaf' : '#1890FF',
            }"
            class="headerSty"
          >
            {{ user_name }}
          </div>
        </a-popover>
        <div
          @click="openPopover"
          style="margin-top: 7px; margin-left: 5px; margin-right: 20px"
        >
          <a-icon style="font-size: 12px" type="caret-down" />
        </div>
        <div v-if="systemText" style="margin-top: 7px;margin-right: 20px">
          {{systemText}}
        </div>
      </div>
    </div>
    <!--认证-->
    <RealNameAuthentication
      :visible="visibleAuthentication"
      :isShow="isShow"
      :jurisdiction="jurisdiction"
      v-on:closeMainRealNameAuth="closeMain"
    ></RealNameAuthentication>
    <!-- 审核中-->
    <UnderReview :visible="visibleUnderReview" v-on:closeMain="closeMains" />

    <!--      系统公告-->
    <MessageNotifi
        v-if="visibleMessageNotifi"
        :visible="visibleMessageNotifi"
        v-on:closeMainMessageNotifi="closeMainMessageNotifi"
    />

<!--    代金卷   -->
    <GiftCard
        v-if="visibleGiftCard"
        :visible="visibleGiftCard"
        v-on:closeMainGiftCard="closeMainGiftCard"
    />
  </div>
</template>

<script>
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
import UnderReview from "@/components/Authentication/UnderReview";
import MessageNotifi from "@/components/meet/MessageNotifi";
import GiftCard from "@/components/meet/GiftCard";
import {getAuthenticationInformation, selectNotReadAllCount, getYCompanyService} from "@/service/authentication";
import { admin_icon } from "@/utils/myIcon";

export default {
  name: "ManageHeader",
  components: {
    UnderReview,
    RealNameAuthentication,
    admin_con: admin_icon,
    GiftCard,
    MessageNotifi
  },
  data() {
    return {
      systemText:'',
      visibleMessageNotifi:false,
      visibleGiftCard:false,
      visible: false,
      jurisdiction: false,
      visibleUser: false,
      visibleAuthentication: false,
      visibleUnderReview: false,
      isShow: {},
      userInfoList: {},
      user_name: "",
    };
  },
  computed: {
    userNameSlice() {
      const user_info = window.localStorage.getItem("yichi_userInfo");
      const name = JSON.parse(user_info).name;
      return name.slice(0, 1);
    },
    user() {
      //判断身份 2为管理员 其他都是用户
      const user_info = window.localStorage.getItem("yichi_userInfo");
      const user = JSON.parse(user_info);
      return user;
    },
    //是否可以弹窗（消息提示或系统弹窗）
    enableMessagePrompt(){
      return this.$store.state.enableMessagePrompt;
    },
    notReadAllCountXT(){
      return this.$store.state.notReadAllCountXT;
    }
  },
  created() {
    // if (this.user.isStaff === 2) {
    //   //管理员
    // } else {
      //普通用户
    this.getInformation();
    this.numberEntries();
    this.getSystemText();
    // }
  },
  methods: {
    //获取系统版本名称
    async getSystemText() {
      const response = await getYCompanyService();
      if (response.code === 200) {
        this.systemText = response.data;
      }
    },


    openMessageNotifi(type){

       if(type === 'XTTZ'){ //打开系统通知
         this.visibleMessageNotifi = true;
       }else if(type === 'DJJ'){ //代金卷
         this.visibleGiftCard = true;
       }
    },
    //获取系统公告未读条数
    async numberEntries() {
      const response = await selectNotReadAllCount();
      if (response.code === 200) {
        // this.NotReadAllCount = response.data > 99 ? '99+':response.data;
        await this.$store.dispatch("notReadAllCountXT", response.data > 99 ? '99+':response.data);

        //自动打开系统通知
        // const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
        // let isKefu = userInfo.isKefu;
        // if(!isKefu && this.enableMessagePrompt && this.NotReadAllCount){ //用户且在允许打开弹窗的条线下打开
        //   this.visibleMessageNotifi = true;
        // }

      } else {
        this.$message.warning("name", response.message);
      }
    },

    //获取个人信息
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        console.log(response.data)
        this.userInfoList = response.data;
        this.user_name = (
          response.data.memberStatus === 2
            ? response.data.companyName
            : response.data.contactsName
        ).slice(0, 1);
      } else {
        this.$message.warning("name", response.message);
      }
    },
    async openPopover() {
      await this.getInformation();
      this.visible = true;
    },
    openPopoverUser() {
      this.visibleUser = true;
    },
    closeMain(val) {
      this.visibleAuthentication = val;
    },
    closeMains(val) {
      this.visibleUnderReview = val;
    },
    openRZ(row) {
      if (row.companyStatus === 1) {
        //待审核
        this.visibleUnderReview = true;
      } else {
        //判断身份
        let companyStatusDW;
        if (row.memberStatus === 2) {
          //企业
          companyStatusDW = 2;
          this.jurisdiction = true;
        } else {
          this.jurisdiction = false;
          companyStatusDW = row.memberStatus;
        }
        this.isShow = {
          companyStatusDW: companyStatusDW,
          id: row.id,
          companyStatus: row.companyStatus,
          memberStatus: row.memberStatus,
        };
        // console.log('信息',this.isShow)
        this.visibleAuthentication = true;
      }
    },
    linkBtn() {
      this.$router.push({ path: "/accountManagement" });
    },
    //  退出登录
    log_out() {
      //清空本地数据
      localStorage.removeItem("yichi_userInfo");
      this.$router.push("/login");
    },
    closeMainMessageNotifi(val) {
      this.visibleMessageNotifi = val;
      this.numberEntries();
    },
    closeMainGiftCard(val) {
      console.log(val)
      this.visibleGiftCard = val;
    },
  },
};
</script>

<style scoped lang="scss">
.headerSty {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-top: 24px;
  margin-left: 10px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.footer {
  height: 64px;
  width: 100%;
  .title {
    font-size: 15px;
    font-weight: 800;
  }
  .info {
    display: flex;
    font-size: 15px;
    .messageNat{
      min-width: 170px;
      display: flex;
      padding-top: 10px;
      .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .numberIcon{
          background-color: red;
          color: white;
          height:15px;
          padding: 3px 5px 5px 5px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 12px;
        }
      }
      .systemNotice{
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .messageNotification{
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
