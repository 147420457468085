import axios from "axios";
import qs from "qs";
import headerConfig from "./api_header_config";
import router from "@/router";

const header = headerConfig.api_header;
// const header = "http://192.168.31.88:10011"; // 韩笑本地
// const header = "http://192.168.5.47:10011"; // 灯珍本地

let x_token;
let x_type;
let x_id;
let set_PWToken;
let channel;

/**
 * 赋值x-token
 * @param token
 */
export function setXToken(id, token, type, setPWToken) {
  x_token = token;
  x_type = type;
  x_id = id;
  set_PWToken = setPWToken;
}
export function setChannel(val) {
    channel = val;
}

axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 50012) {
      //清除本地的记录
      sessionStorage.removeItem("yichi_userInfo");
      router.push({
        path: "/login",
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//封装get
export function get(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: {
        Token: x_token,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//封装get --- byid用户id---客服列表
export function getByIdKF(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: {
          Token: x_token,
          "byId": params.byId,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//封装get
export function getById(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: {
          Token: x_token,
          "byId": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getXToken(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: {
        Token: x_token,
        "x-token": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//下载表格----params.ExelName为表格名字
export function getExel(url, params) {
  return axios
    .get(header + url, {
      headers: { Token: x_token },
      params,
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf8",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = params.ExelName + ".xlsx"; //下载后文件名
      // downloadElement.download =decodeURIComponent(
      //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}

// 下载zip

export function getZip(url, params) {
  return axios
    .get(header + url, {
      headers: { Token: x_token },
      params,
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        // type: "application/vnd.ms-excel;charset=utf8",
        type:"application/zip"
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = params.ExelName + ".zip"; //下载后文件名
      // downloadElement.download =decodeURIComponent(
      //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}
//下载表格----params.ExelName为表格名字
export function postExel(url, params) {
  return axios
    .post(header + url,params, {
      headers: { Token: x_token },
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf8",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = params.ExelName + ".xlsx"; //下载后文件名
      // downloadElement.download =decodeURIComponent(
      //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}
//下载world----params.ExelName为表格名字
export function postWorld(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        Token: x_token,
        "x-token": x_id,
      },
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf8",
      });
      let elink = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      elink.href = href;
      elink.download = params.worldName + ".docx"; //下载后文件名
      document.body.appendChild(elink);
      elink.click(); //点击下载
      document.body.removeChild(elink); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}
//params.byId  客服创建会议的用户id
export function postKfOrYH(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        Token: x_token,
        "x-token": params.byId ? params.byId : x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function post(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
        Token: x_token,
        "x-token": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postById(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
          Token: x_token,
          "byId": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postByIdChannel(url, params) {
  return axios
    .post(header + url, params, {
      headers: {
          Token: x_token,
          "byId": x_id,
          "channel": channel,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function postLogin(url, params) {
  return axios
    .post(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function put(url, params) {
  return axios
    .put(header + url, params, {
      headers: {
        Token: x_token,
        "x-token": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function putById(url, params) {
  return axios
    .put(header + url, params, {
      headers: {
          Token: x_token,
          "byId": x_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function putSetPassWord(url, params) {
  return axios
    .put(header + url, params, {
      headers: {
        Token: set_PWToken,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function del(url, params) {
  return axios
    .delete(header + url, {
      headers: {
        Token: x_token,
        "x-token": x_id,
      },
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function delBiId(url, params) {
  return axios
    .delete(header + url, {
      headers: {
        Token: x_token,
        "byId": x_id,
      },
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
