<template>
  <div>
    <!--实名认证弹窗-->
    <a-modal
      width="750px"
      title="实名认证"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div v-if="isShow">
        <!--      只有当认证状态为未通过时显示-->
        <!--      //认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核   -->
        <a-alert
          v-if="isShow.companyStatus === 2"
          type="error"
          show-icon
          style="margin-bottom: 15px"
        >
          <div slot="message">
            <div v-for="(liet, index) in certificationRemark" :key="index">
              <div v-if="liet">{{ liet }}</div>
            </div>
            <!--          <div v-html="isShow.certificationRemark"></div>-->
            <!--          <div >{{isShow.certificationRemark}}</div>-->
          </div>
        </a-alert>
      </div>
      <!--    切换按钮  -->
      <div class="modelSty">
        <a-radio-group
          v-model="radioVal"
          button-style="solid"
          @change="assignment"
        >
          <a-radio-button
            value="personage"
            :disabled="isShow.memberStatus === 2"
          >
            个人认证
          </a-radio-button>
          <a-radio-button style="margin-left: 5px" value="enterprise">
            企业认证
          </a-radio-button>
        </a-radio-group>
      </div>
      <div v-if="radioVal === 'personage'">
        <!--个人认证-->
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="tel">
            <a-input v-model="form.tel" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="验证码" prop="code">
            <a-input
              v-model="form.code"
              class="sign-up-input"
              placeholder="请输入验证码"
            >
              <a-tooltip slot="suffix">
                <a
                  type="primary"
                  @click="phoneCodeLogin"
                  :disabled="phoneCodeLoginDisabled"
                  >{{ phoneCodeLoginText }}</a
                >
              </a-tooltip>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="身份证号码" prop="IDcode">
            <a-input v-model="form.IDcode" placeholder="请输入" />
          </a-form-model-item>
<!--          <a-form-model-item label="手持身份证照片(人像面)" prop="IDImg">-->
<!--          <a-form-model-item label="手持身份证照片(人像面)">-->
<!--            <div style="display: flex">-->
<!--              <div class="uploadImg" style="position: relative">-->
<!--                <input-->
<!--                  :disabled="IDImgDisabled"-->
<!--                  id="IDImgId"-->
<!--                  style="width: 100%; height: 100%; opacity: 0"-->
<!--                  accept=".jpeg,.png,.jpg"-->
<!--                  type="file"-->
<!--                  @change="upIDImg"-->
<!--                  ref="IDImgRef"-->
<!--                />-->
<!--                <img v-if="form.IDImg" class="imgSty" :src="form.IDImg" />-->
<!--                <div class="hoverBgc" v-if="form.IDImg">-->
<!--                  <div class="iconDel">-->
<!--                    <admin_con-->
<!--                      @click="-->
<!--                        () => {-->
<!--                          form.IDImg = '';-->
<!--                        }-->
<!--                      "-->
<!--                      type="icon-shanchu1"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <a-icon v-if="form.IDImg" type="close-circle" class="upImgDel" @click="delIDImg"/>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="upload_image" @click="openPic(PortraitPlane)">-->
<!--                <img class="imgIdC" :src="PortraitPlane" />-->
<!--                <div class="seachIcon">示例图 <a-icon type="search" /></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="font-size: 12px; color: #929292">-->
<!--              请上传格式为jpeg、png、jpg的图片-->
<!--            </div>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="手持身份证照片(国徽面)" prop="IDImgs">-->
<!--          <a-form-model-item label="手持身份证照片(国徽面)">-->
<!--            <div style="display: flex">-->
<!--              <div class="uploadImg" style="position: relative">-->
<!--                <input-->
<!--                  :disabled="IDImgDisabled"-->
<!--                  id="IDImgsId"-->
<!--                  style="width: 100%; height: 100%; opacity: 0"-->
<!--                  accept=".jpeg,.png,.jpg"-->
<!--                  type="file"-->
<!--                  @change="upIDImgs"-->
<!--                  ref="IDImgsRef"-->
<!--                />-->
<!--                <img v-if="form.IDImgs" class="imgSty" :src="form.IDImgs" />-->
<!--                <div class="hoverBgc" v-if="form.IDImgs">-->
<!--                  <div class="iconDel">-->
<!--                    <admin_con-->
<!--                      @click="-->
<!--                        () => {-->
<!--                          form.IDImgs = '';-->
<!--                        }-->
<!--                      "-->
<!--                      type="icon-shanchu1"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <a-icon v-if="form.IDImgs" type="close-circle" class="upImgDel" @click="delIDImgs"/>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="upload_image" @click="openPic(nationalEmblem)">-->
<!--                <img class="imgIdC" :src="nationalEmblem" />-->
<!--                <div class="seachIcon">示例图 <a-icon type="search" /></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="font-size: 12px; color: #929292">-->
<!--              请上传格式为jpeg、png、jpg的图片-->
<!--            </div>-->
<!--          </a-form-model-item>-->
        </a-form-model>
      </div>
      <div v-else>
        <!--企业认证-->
        <a-form-model
          ref="ruleFormQ"
          :model="formQ"
          :rules="rulesQ"
          :label-col="labelColQ"
          :wrapper-col="wrapperCol"
        >
          <div
            style="
              font-weight: bold;
              color: rgba(0, 0, 0, 0.85);
              margin-left: 30px;
              margin-bottom: 20px;
            "
          >
            企业信息
          </div>
          <a-form-model-item label="企业名称" prop="nameFirm">
            <a-input v-model="formQ.nameFirm" placeholder="请输入" :disabled="jurisdiction"/>
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码" prop="creditCode">
            <a-input v-model="formQ.creditCode" placeholder="请输入" :disabled="jurisdiction"/>
          </a-form-model-item>
          <a-form-model-item label="营业执照" prop="business">
            <div style="display: flex">
              <div class="uploadImg" style="position: relative">
<!--                :disabled="IDImgDisabled"  上传图片中不允许再次点击  -->
                <input
                  :disabled="jurisdiction"
                  id="businessId"
                  style="width: 100%; height: 100%; opacity: 0"
                  accept=".jpeg,.png,.jpg"
                  type="file"
                  @change="upIDImgQ"
                  ref="businessRef"
                />
                <img
                  v-if="formQ.business"
                  class="imgSty"
                  :src="formQ.business"
                />
                <div class="hoverBgc" v-if="formQ.business">
                  <div class="iconDel">
                    <admin_con
                      @click="
                        () => {
                          formQ.business = '';
                        }
                      "
                      type="icon-shanchu1"
                    />
                  </div>
                </div>
                <!--              <a-icon v-if="formQ.business" type="close-circle" class="upImgDel" @click="delBusiness"/>-->
              </div>
            </div>
            <div style="font-size: 12px; color: #929292">
              请上传格式为jpeg、png、jpg的图片
            </div>
          </a-form-model-item>
          <div
            style="
              font-weight: bold;
              color: rgba(0, 0, 0, 0.85);
              margin-left: 30px;
              margin-bottom: 20px;
              margin-top: 50px;
            "
          >
            联系人信息
          </div>
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="formQ.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="tel">
            <a-input v-model="formQ.tel" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="验证码" prop="code">
            <!--            <a-input v-model="form.code" placeholder="请输入"/>-->
            <a-input
              v-model="formQ.code"
              class="sign-up-input"
              placeholder="请输入验证码"
            >
              <a-tooltip slot="suffix">
                <a
                  size="small"
                  type="primary"
                  @click="phoneCodeLoginQ"
                  :disabled="phoneCodeLoginDisabledQ"
                  >{{ phoneCodeLoginTextQ }}</a
                >
              </a-tooltip>
            </a-input>
          </a-form-model-item>

<!--          <a-form-model-item label="授权书" prop="authorization">-->
<!--            <div style="display: flex">-->
<!--              <div class="uploadImg" style="position: relative">-->
<!--                <input-->
<!--                  id="upAuthorizationId"-->
<!--                  style="width: 100%; height: 100%; opacity: 0"-->
<!--                  accept="image/*"-->
<!--                  type="file"-->
<!--                  @change="upAuthorization"-->
<!--                  ref="upAuthorizationRef"-->
<!--                />-->
<!--                <img-->
<!--                  v-if="formQ.authorization"-->
<!--                  class="imgSty"-->
<!--                  :src="formQ.authorization"-->
<!--                />-->
<!--                <div class="hoverBgc" v-if="formQ.authorization">-->
<!--                  <div class="iconDel">-->
<!--                    <admin_con-->
<!--                      @click="-->
<!--                        () => {-->
<!--                          formQ.authorization = '';-->
<!--                        }-->
<!--                      "-->
<!--                      type="icon-shanchu1"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;              <a-icon v-if="formQ.authorization" type="close-circle" class="upImgDel" @click="delAuthorization"/>&ndash;&gt;-->
<!--              </div>-->
<!--              <div style="margin-top: 80px; margin-left: 10px">-->
<!--                <a-->
<!--                  href="https://yichi-1252497858.cos.ap-guangzhou.myqcloud.com/%E5%8C%BB%E9%A9%B0%E4%BC%81%E4%B8%9A%E6%8E%88%E6%9D%83%E4%B9%A6.docx"-->
<!--                  >下载授权书</a-->
<!--                >-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="upload_image" v-else>&ndash;&gt;-->
<!--              &lt;!&ndash;              <img&ndash;&gt;-->
<!--              &lt;!&ndash;                  alt=""&ndash;&gt;-->
<!--              &lt;!&ndash;                  style="width: 100%;height:100%"&ndash;&gt;-->
<!--              &lt;!&ndash;                  :src="formQ.authorization"/>&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div style="font-size: 12px; color: #929292">-->
<!--              请上传格式为jpeg、png、jpg的拍照或扫描件授权书，要求内容及盖章清晰-->
<!--            </div>-->
<!--          </a-form-model-item>-->
        </a-form-model>
      </div>
      <div style="text-align: center; color: #929292; font-size: 12px">
        <div>认证信息仅用于身份验证，医驰会务平台会进行严格保密。</div>
      </div>
    </a-modal>
    <PicModel
      v-on:picModelSwitch="picModelSwitch"
      :pic-val="picVal"
      :visible="visiblePic"
    >
    </PicModel>
    <!-- 待审核界面 -->
    <UnderReview v-on:closeMain="closeMainReview" :visible="visibleReview" />
  </div>
</template>

<script>
import { update } from "@/utils/update";
import IdentityCodeValid from "@/utils/checkIdent";
import { postLoginPhoneCode } from "@/service/common";
import {
  dutyParagraphIsRepeat,
  getAuthenticationInformation,
  getPowerOfAttorney,
  getSubmitCertification,
  sendSMS,
  submitCertification,
} from "@/service/authentication";
import PicModel from "@/components/Authentication/PicModel";
import { admin_icon } from "@/utils/myIcon";
import UnderReview from "@/components/Authentication/UnderReview";

export default {
  name: "RealNameAuthentication",
  components: {
    UnderReview,
    PicModel,
    admin_con: admin_icon,
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    //默认情况不允许修改,企业信息
    jurisdiction: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Object,
    },

    isFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    let validatePass = async (rule, value, callback) => {
      const reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
      if (!value) {
        return callback(); //取消报错
      } else {
        if (!reg.test(value)) { //格式错误
          return callback(new Error('请输入正确的统一社会信用代码'));
        } else { //格式正确，判断是否重复
          let data = {
            type:'taxNumber', // 类型 isCard:身份证号码  taxNumber:信用码
            taxNumber:value
          }
            const response = await dutyParagraphIsRepeat(data)
            if(response.code === 0) {
              if (response.data) { //没重复
                return callback(); //取消报错
              } else { //重复
                return callback(new Error('统一社会信用代码已存在，请重新输入'));
              }
            }
        }
      }
    };
    let validatePassId = async (rule, value, callback) => {
      const reg = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[Xx\d]$/;
      if (!value) {
        return callback(); //取消报错
      } else {
        if (!reg.test(value)) { //格式错误
          return callback(new Error('请输入正确的身份证号码'));
        } else { //格式正确，判断是否重复
          let data = {
            type:'IDcode', // 类型 IDcode:身份证号码  taxNumber:信用码
            taxNumber:value
          }
            const response = await dutyParagraphIsRepeat(data)
            if(response.code === 0) {
              if (response.data) { //没重复
                return callback(); //取消报错
              } else { //重复
                return callback(new Error('身份证号码已存在，请重新输入'));
              }
            }
        }
      }
    };
    return {
      picVal: "",
      visiblePic: false,
      visibleReview: false,
      nationalEmblem:require('@/assets/icon/FICon.jpg'),
      PortraitPlane:require('@/assets/icon/oneIcon.jpg'),
      // IdentityCodeValid:IdentityCodeValid(),
      // visible:false,
      radioVal: "personage",

      labelCol: { span: 7 },
      labelColQ: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        tel: "",
        code: "",
        IDcode: "",
        IDImg: "",
        IDImgs: "",
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        IDcode: [
          { required: true, validator: validatePassId, trigger: "blur" },
          // {
          //   pattern:
          //     /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[Xx\d]$/,
          //   message: "请输入正确的身份证号码",
          //   trigger: "blur",
          // },
        ],
        // IDImg: [{ required: true, message: "请上传" }],
        // IDImgs: [{ required: true, message: "请上传", trigger: "blur" }],
      },
      formQ: {
        nameFirm: "",
        creditCode: "",
        business: "",
        name: "",
        tel: "",
        code: "",
        // authorization: "",
      },
      rulesQ: {
        nameFirm: [{ required: true, message: "请输入", trigger: "blur" }],
        creditCode: [
          { required: true,validator: validatePass, trigger: "blur" },
          // {
          //   pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
          //   message: "请输入正确的统一社会信用代码",
          //   trigger: "blur",
          // },
        ],
        business: [{ required: true, message: "请上传" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        // authorization: [{ required: true, message: "请上传" }],
      },
      phoneCodeLoginDisabled: false,
      phoneCodeLoginText: "获取验证码",
      phoneCodeLoginDisabledQ: false,
      phoneCodeLoginTextQ: "获取验证码",
      IDImgDisabled: false,
      certificationRemark: "",
    };
  },
  created() {},
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        ////认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核
        //"memberStatus": 0,//账号类型 0未认证 1个人认证 2企业认证
        if (this.isShow.memberStatus === 2) {
          this.radioVal = "enterprise";
        } else {
          if (this.isShow.companyStatusDW === 2) {
            this.radioVal = "enterprise";
          } else {
            this.radioVal = "personage";
          }
        }
        this.assignment();
      }
    },
  },
  methods: {
    closeMainReview(val) {
      this.visibleReview = val;
    },
    picModelSwitch(val) {
      this.visiblePic = val;
    },
    openPic(img) {
      this.picVal = img;
      this.visiblePic = true;
    },
    delIDImgs() {
      this.form.IDImgs = "";
    },
    delIDImg() {
      this.form.IDImg = "";
    },
    delBusiness() {
      this.formQ.business = "";
    },
    delAuthorization() {
      // this.formQ.authorization = "";
    },
    async assignment() {
      // console.log('获取到的信息',this.isShow)
      // let row = this.isShow;
      // let data = {
      //   id: row.id,
      // };
      // const response = await getSubmitCertification(data);
      const response = await getSubmitCertification();
      if (response.code === 200) {
        // console.log('1111111111',response.data)
        let list = response.data;
        if (this.radioVal === "personage") {
          //个人认证
          this.form = {
            name: list.contactsName,
            tel: list.contactsTel,
            IDcode: list.contactsIdCard,
            // IDImg: list.handheldIdCardPhotoFront,
            // IDImgs: list.handheldIdCardPhotoBack,
          };
        } else {
          //企业认证
          this.formQ = {
            nameFirm: list.name,
            creditCode: list.taxNumber,
            business: list.businessLicense,
            name: list.contactsName,
            tel: list.contactsTel,
            // authorization: list.powerOfAttorney,
          };
        }
        if (list.certificationRemark) {
          this.certificationRemark = list.certificationRemark.split("\n");
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    async upIDImg() {
      this.IDImgDisabled = true;
      let inputDOM = this.$refs.IDImgRef.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.form.IDImg = image.data.url;
        this.$message.success("上传成功");
        this.$refs.ruleForm.validateField("IDImg"); //校验上传成功，取消显示请上传
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById("IDImgId").value = null;
      this.IDImgDisabled = false;
    },
    async upIDImgs() {
      this.IDImgDisabled = true;
      let inputDOM = this.$refs.IDImgsRef.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.form.IDImgs = image.data.url;
        this.$message.success("上传成功");
        this.$refs.ruleForm.validateField("IDImgs"); //校验上传成功，取消显示请上传
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById("IDImgsId").value = null;
      this.IDImgDisabled = false;
    },
    async upIDImgQ() {
      this.IDImgDisabled = true;
      let inputDOM = this.$refs.businessRef.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.formQ.business = image.data.url;
        this.$message.success("上传成功");
        this.$refs.ruleFormQ.validateField("business"); //校验上传成功，取消显示请上传
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById("businessId").value = null;
      this.IDImgDisabled = false;
    },
    async upAuthorization() {
      this.IDImgDisabled = true;
      let inputDOM = this.$refs.upAuthorizationRef.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.formQ.authorization = image.data.url;
        this.$message.success("上传成功");
        this.$refs.ruleFormQ.validateField("authorization"); //校验上传成功，取消显示请上传
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById("upAuthorizationId").value = null;
      this.IDImgDisabled = false;
    },
    handleOk() {
      if (this.radioVal === "personage") {
        //个人认证
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let form = this.form;
            let data = {
              id: this.isShow.id, //登录时获取当前用户的公司id
              contactsName: form.name, //"联系人姓名",
              contactsTel: form.tel, //"联系人手机号",
              contactsIdCard: form.IDcode, //"联系人身份证号",
              yanZhengMa: form.code,
              // handheldIdCardPhotoFront: form.IDImg, //"手持身份证照片（人面像）",
              // handheldIdCardPhotoBack: form.IDImgs, //"手持身份证照片（国徽像）",
            };
            this.upAuthentication(data);
          } else {
            return false;
          }
        });
      } else {
        //企业认证
        this.$refs.ruleFormQ.validate((valid) => {
          if (valid) {
            let formQ = this.formQ;
            let dataQ = {
              id: this.isShow.id, //登录时获取当前用户的公司id
              name: formQ.nameFirm, // "公司名称",
              taxNumber: formQ.creditCode, // "公司税号-社会统一码",
              businessLicense: formQ.business, // "营业执照照片",
              contactsName: formQ.name, // "联系人姓名",
              contactsTel: formQ.tel, // "联系人手机号",
              yanZhengMa: formQ.code,
              contactsIdCard: formQ.nameFirm, // "联系人身份证号",
              // powerOfAttorney: formQ.authorization, // "授权书照片"
            };
            this.upAuthentication(dataQ);
          } else {
            return false;
          }
        });
      }
    },
    //提交认证
    async upAuthentication(data) {
      const response = await submitCertification(data);
      if (response.code === 200) {
        let flag = false;
        this.$emit("closeMainRealNameAuth", flag); //关闭认证弹窗
        this.$emit("checkList", true); //子组件向父组件传值

        //若在免费领取搭建会议时认证，则需要调取领取认证成功后的免费搭建会议一次
        if(this.isFrom === 'Free'){
          this.$parent.$parent.postReceiveService();
        }
        //若在免费优惠卷/代金卷时认证，则需要调取一键领取代金卷
        if(this.isFrom === 'Voucher'){
          this.$parent.$parent.postReceiveVoucher();
        }

        if (this.radioVal === "personage") {
          //个人认证
          this.$refs.ruleForm.resetFields();
        } else {
          this.$refs.ruleFormQ.resetFields();
        }
        await this.getInformation();
        this.visibleReview = true;
        // this.$message.success("操作成功！")
      } else {
        this.$message.warning(response.message);
      }
    },
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        //更改本地存储中的认证状态以及身份状态
        //1.获取本地存储内容
        const user_info = window.localStorage.getItem("yichi_userInfo");
        const user = JSON.parse(user_info);
        let data = {
          companyId: user.companyId,
          companyName: user.companyName,
          companyStatus: response.data.companyStatus, //认证状态
          id: user.id,
          isStaff: user.isStaff,
          memberStatus: response.data.memberStatus, //身份状态
          name: user.name,
          tel: user.tel,
          token: user.token,
        };
        window.localStorage.setItem("yichi_userInfo", JSON.stringify(data));
        //更改公共数据
        await this.$store.dispatch("userInfoList", data);

        //子组件调用父组件数据
        this.$parent.getInformation();
      } else {
        this.$message.warning(response.message);
      }
    },
    handleCancel() {
      let flag = false;
      this.$emit("closeMainRealNameAuth", flag); //子组件向父组件传值（开关）
      //免费搭建会议一次  关闭时打开领取优惠卷
      if(this.isFrom === 'Free'){
        this.$store.dispatch("isOpenVoucherModel",true); // 打开优惠卷开关
      }
      //一键领取代金卷
      if(this.isFrom === 'Voucher'){
        this.$store.dispatch("isOpenVoucherModel",false); // 打开优惠卷开关
      }


      if (this.radioVal === "personage") {
        //个人认证
        this.$refs.ruleForm.resetFields();
      } else {
        this.$refs.ruleFormQ.resetFields();
      }
    },
    async phoneCodeLogin() {
      //验证码登录-获取验证码
      this.$refs.ruleForm.validateField("tel", async (valid) => {
        if (!valid) {
          //不为空
          let data = {
            tel: this.form.tel,
          };
          const response = await sendSMS(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60s(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        }
      });
    },
    async phoneCodeLoginQ() {
      //验证码登录-获取验证码
      this.$refs.ruleFormQ.validateField("tel", async (valid) => {
        if (!valid) {
          //不为空
          let data = {
            tel: this.formQ.tel,
          };
          const response = await sendSMS(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60sQ(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        }
      });
    },
    phoneCodeLogin60sQ() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabledQ = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginTextQ = count + "s";
        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginTextQ = "重新获取";
          this.phoneCodeLoginDisabledQ = false;
        }
      }, 1000);
    },
    phoneCodeLogin60s() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabled = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginText = count + "s";

        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginText = "重新获取";
          this.phoneCodeLoginDisabled = false;
        }
      }, 1000);
    },
    test() {
      let ident = "371425199805302108"; //假身份证
      let data = ident.toUpperCase(); //转换成大写
      // console.log(IdentityCodeValid( data ));
    },
    loadWord() {
      // if(row.nameFirm || row.tel){
      //   let data = {
      //     name:row.nameFirm, // 公司名称
      //     tel:row.tel, // 手机号
      //     worldName:'企业授权书',
      //   }
      //   await getPowerOfAttorney(data);
      // }else {
      //   this.$message.warning("请先填写企业名称或者手机号码！")
      // }
      window.open(
        "https://yichi-1252497858.cos.ap-guangzhou.myqcloud.com/shouquanshu.docx"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.modelSty {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.uploadImg {
  border: #dddddd 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  .imgSty {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .upImgDel {
    color: red;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .hoverBgc {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    opacity: 0; //透明度为0 不显示遮罩层
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 25px;
    .iconDel {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .hoverBgc:hover {
    opacity: 1;
  }
}
.upload_image {
  border: #dddddd 2px dashed;
  width: 150px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
  .imgIdC {
    width: 90%;
    height: 70%;
    margin: auto;
    padding-top: 5px;
  }
  .seachIcon {
    color: #929292;
    cursor: pointer;
  }
}
</style>
