<template>
<div>
<!--图片弹窗-->
  <a-modal
      width="600px"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
  >
    <div>
      <img style="width: 100%;margin-top: 20px" :src="picVal" alt="">
    </div>
  </a-modal>
</div>
</template>

<script>
export default {
  name: "UnderReview",
  props:{
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: true,
    },
    picVal: {
      type: String,
    },
  },
  data(){
    return{
    }
  },
  methods:{
    handleCancel(){
      let flag = false;
      this.$emit("picModelSwitch", flag); //子组件向父组件传值（开关）
    }
  }
}
</script>

<style scoped lang="scss">

</style>
