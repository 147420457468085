<template>
<div>
<!--  审核中-->
  <a-modal
      width="600px"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
  >
   <div class="modelSty">
     <admin_con style="font-size: 90px" type="icon-tongguo"/>
     <div class="message">提交成功</div>
     <div class="timeMess">审核时间：工作日9:30-18:00</div>
     <div class="timeMess">预计两小时审核完成</div>
   </div>
  </a-modal>
</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";

export default {
  name: "UnderReview",
  components:{
    admin_con: admin_icon,
  },
  props:{
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      // visible:true,
    }
  },
  methods:{
    handleCancel(){
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
    }
  }
}
</script>

<style scoped lang="scss">
  .modelSty{
    text-align: center;margin-top: 50px;margin-bottom: 80px;
    .message{
      font-size: 25px;font-weight: bold;color: rgb(0,0,0,.85);margin-top: 20px;
    }
    .timeMess{
      color: #929292;margin-top: 15px;
    }
  }
</style>
