<template>
  <!-- 运营菜单管理 -->
  <div class="manageSider">
    <div class="logo">
      <!-- <div @click="() => { this.$router.push('/meetingManagement');this.select = '[0]'; }" style="cursor: pointer">
        <img src="@/assets/yich_logo.png" style="width: 90px;" />
      </div> -->
      <img src="@/assets/yich_logo.png" style="width: 70px" />
      <span>运营管理后台</span>
    </div>
    <div class="siderMenu">
      <a-menu
        mode="inline"
        style="border-top: 1px solid #f5f5f5; border-right: none;"
        :selectedKeys="[$route.path]"
        v-model="select"
         @openChange="onOpenChange"
         :open-keys="openKeys"

      >
        <a-sub-menu
          style="margin-left: 12px"
          :key="menu.id"
          v-for="menu in menus"
        >
          <span slot="title">
            <img
              style="
                width: 25px;
                height: 25px;
                margin-right: 8px;
                margin-left: -10px;
              "
              :src="menu.icon === '' ? img : menu.icon"
              alt=""
            />
            <span>{{ menu.name }}</span>
          </span>
          <a-menu-item
            :key="menuChildren.path"
            v-for="menuChildren in menu.item"
            @click="menuTitle(menu.name, menuChildren.name)"
          >
            <router-link :to="menuChildren.path">
              <span style="margin-left: -1px">{{ menuChildren.name }}</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { manageMenus } from "@/service/menu_api";
import { getMenu } from "@/service/columnmanageapi";
export default {
  name: "ManageSider",
  props: ["collapsed"],
  data() {
    return {
      menus: [],
      select: ["0"],
      openKeys: [],
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
    };
  },
  async created() {
    await this.getMenus();
     await this.change_select();
  },
  computed: {
    liveHistory() {
      return this.$store.state.liveMeetHistory;
    },
  },
  mounted() {},
  watch: {},
  methods: {
    async change_select() {
      let path = this.$route.path;
      let select = [];
      select.push(path);
      this.select = select;
      let menuData = this.menus;
      for (const menuDataKey in menuData) {
        for (const itemKey in menuData[menuDataKey].childMenu) {
          let myList = [];
          if (menuData[menuDataKey].childMenu[itemKey].path === path) {
            myList.push(menuData[menuDataKey].childMenu[itemKey].parentId);
            this.openKeys = myList;
            break;
          }
        }
      }
    },
    //获取菜单数据
    async getMenus() {
      let data = {
        isOpen: 1,
        isStaff: 2,
      };
      const response = await getMenu(data);
      if (response.code === 0) {
        this.menus = response.data;
        this.menus.forEach(element => {
          this.openKeys.push(element.id)
        });

      }
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    menuTitle(FTitle, STitle) {
      this.$store.dispatch("manageHeaderTitleFTitle", FTitle);
      this.$store.dispatch("manageHeaderTitleSTitle", STitle);
      this.$store.dispatch("isShowNav", true); //恢复导航栏
      // 判断是菜单点击进入
      this.liveHistory.isRouter = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.siderMenu  {
  //padding-left: 20px !important;

}
.logo {
  height: 64px;
  display: flex;
  justify-content: start;
  align-items: center;
  span {
    color: #1890ff;
    font-weight: 550;
    font-size: 18px;
    margin-top: 4px;
  }
}
</style>
