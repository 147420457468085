<template>
<div>
<!--  通知弹窗  -->
  <a-modal
      :dialogStyle="{ 'top': '8%',}"
      width="900px"
      :visible="visible"
      :footer="false"
      @cancel="handleCancel"
  >
    <div v-if="notReadAllCountXT">
      <div class="messageNotifi">
        <div class="titleInfo"><a-icon style="font-size: 30px;color: #EA9518;margin-right: 5px;" type="info-circle" theme="filled" /> <span >消息通知</span></div>
        <div style="margin-left: 41px;color: #333333;padding-top: 60px;" v-if="NotReadAllCountList.content">
          <div v-html="NotReadAllCountList.content"></div>
        </div>

        <div class="paperClip" v-if="NotReadAllCountList.annex">
          <div>
            <a-icon style="font-size: 25px;padding-right: 10px;" type="paper-clip" /><span style="font-size: 18px;font-weight: bold">附件</span>
          </div>
          <div class="contentPdf" v-for="(item,index) in NotReadAllCountList.annex" :key="index">
            <div>{{item.title}}</div>
            <div style="margin-left: 20px;"><a @click="openUrl(item.url)">预览</a></div>
          </div>
        </div>
      </div>

      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          display:'flex',
          height:'50px',
          justifyContent:'space-between'
        }">
        <div style="width: 33%;"></div>
        <div style="width: 34%;text-align: center">
          第 <span style="color: #1890FF">{{pageNo}}</span> 篇，共 {{notReadAllCountXT}} 篇
        </div>
        <div style="width: 33%;">
          <a-button  @click="handleCancel">
            稍后查看
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="markRead">
            标记已读
          </a-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="messageNotifi">
        <div class="titleInfo"><a-icon style="font-size: 30px;color: #EA9518;margin-right: 5px;" type="info-circle" theme="filled" /> <span >消息通知(全部)</span></div>

        <div v-if="allCount === 0" style="margin-top: 20%;text-align: center;color: #9c9b9b">
          <admin_con style="font-size: 100px;margin-bottom: 10px" type="icon-zanwutongzhi1"/>
          <br/>
          暂无通知
        </div>

        <div style="margin-left: 41px;color: #333333;padding-top: 60px;" v-if="NotReadAllCountList.content">
          <div v-html="NotReadAllCountList.content"></div>
        </div>

        <div class="paperClip" v-if="NotReadAllCountList.annex">
          <div>
            <a-icon style="font-size: 25px;padding-right: 10px;" type="paper-clip" /><span style="font-size: 18px;font-weight: bold">附件</span>
          </div>
          <div class="contentPdf" v-for="(item,index) in NotReadAllCountList.annex" :key="index">
            <div>{{item.title}}</div>
            <div style="margin-left: 20px;"><a @click="openUrl(item.url)">预览</a></div>
          </div>
        </div>
      </div>
      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          display:'flex',
          height:'50px',
          justifyContent:'space-between'
        }">
        <div style="width: 33%;">
          <a-button style="margin-left: 10px" @click="previousPage" :disabled="pageNo === 1">
            上一页
          </a-button>
        </div>
        <div style="width: 34%;text-align: center">
          第 <span style="color: #1890FF">{{pageNo}}</span> 篇，共 {{allCount}} 篇
        </div>
        <div style="width: 33%;display: flex;justify-content: left">
          <a-button @click="nextPage" :disabled="pageNo === allCount">
            下一页
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script>
import {postSignRead, selectNotReadAll, selectReadAll} from "@/service/authentication";
import {admin_icon} from "@/utils/myIcon";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";

export default {
  name: "MessageNotifi",
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isNext: { //关闭弹窗后是否打开下一个弹窗
      type: Boolean,
      default: false,
    },
  },
  components:{admin_con: admin_icon,},
  data(){
    return{
      pageNo:1,
      NotReadAllCountList:{},

      allCount:0,
    }
  },
  computed:{
    notReadAllCountXT(){
      return this.$store.state.notReadAllCountXT;
    }
  },
  created() {
    console.log(1111111111111111)
    if(this.notReadAllCountXT){
      this.getSelectNotRead();
    }else {
      this.getSelectAllRead();
    }
  },
  methods:{
    async getSelectNotRead() {
      let data = {
        // page:this.pageNo,
        page:1,
        pageSize:1
      }
      const response = await selectNotReadAll(data);
      if (response.code === 200) {
        this.NotReadAllCountList = response.data[0];
      } else {
        this.$message.warning(response.message);
      }
    },
    async getSelectAllRead() {
      let data = {
        page: this.pageNo,
        pageSize: 1
      }
      const response = await selectReadAll(data);
      if (response.code === 200) {
        this.NotReadAllCountList = response.data[0];
        this.allCount = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    nextPage(){
      if(this.pageNo < this.allCount){
        this.pageNo ++;
        this.getSelectAllRead();
      }

    },
    previousPage(){
      if(this.pageNo > 1){
        this.pageNo --;
        this.getSelectAllRead();
      }
    },
    handleCancel(){
      //关闭弹窗，等下次登录时再打开
      this.$store.dispatch("enableMessagePrompt",false)
      //关闭当前弹窗，打开 免费领取搭建微网站
      if(this.isNext){
        this.$store.dispatch("isOpenFreeSetMeeting",true);
        this.$parent.$parent.isPopUpWindows();
      }
      let flag = false;
      this.$emit("closeMainMessageNotifi", flag); //子组件向父组件传值（开关）
    },
    async markRead() {
      const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
      //标记已读
      let data = {
        "memberId": userInfo.id,//用户id
        "sysNotId": this.NotReadAllCountList.id//通知公告id
      }
      const response = await postSignRead(data);
      if (response.code === 200) {
        if (this.pageNo < this.notReadAllCountXT) {
          this.pageNo++; //下一页
          this.NotReadAllCountList = {};
          this.getSelectNotRead();
        }else if(this.pageNo === this.notReadAllCountXT){ //最后一页关闭弹窗
          this.NotReadAllCountList = {};
          this.getSelectNotRead();

          this.handleCancel();
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    openUrl(url){
      window.open(url);
    }
  }
}
</script>

<style scoped lang="scss">
.messageNotifi{
  width: 100%;
  height: 500px;
  //background-color: #333333;
  overflow-y: scroll;
  padding: 20px;
  margin-bottom: 20px;
  /*justify-content: space-between;*/
  .titleInfo{
    position: absolute;
    top: 0;
    padding-top: 40px;
    background-color: #ffffff;
    width: 95%;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .paperClip{
    margin-top: 20px;
    background-color: #F1F1F1;
    padding: 20px;
    border-radius: 10px;
    color: #333333;
    .contentPdf{
      margin-top: 20px;
      padding-left: 35px;
      display: flex;
    }
  }
}
</style>
