import Vue from "vue";
import VueRouter from "vue-router";
import ManageRoot from "../views/ManageRoot";
// import managementOfMeetingsRoot from "../views/managementOfMeetingsRoot";

Vue.use(VueRouter);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));

const routes = [
  {
    path: "/",
    // name: 'ManageRoot',
    component: ManageRoot,
    children: [
      //医学会议管理--会议管理
      {
        path: "/meetingManagement",
        name: "MeetingManagement",
        redirect:userInfo && userInfo.isKefu == 1 ?'/myClient':'/PathmeetingManagement',  //如果是客服账号就跳转/myClient
        component: () =>
          import("@/views/content/medicalConference/MeetingManagement"),
      },
      //医学会议管理--会议管理
      {
        path: "/PathmeetingManagement",
        name: "PathmeetingManagement",
        component: () =>
          import("@/views/content/medicalConference/MeetingManagement"),
      },
      {
        path: "/dataStatistics",
        name: "DataStatistics",
        redirect: "/HomepageDataReport",
        component: () =>
          // import("@/views/content/medicalConference/dataAnalysis/HomepageDataReport"),
          import("@/views/content/medicalConference/DataStatistics"),
        children: [
          {
            // 首页数据
            path: "/HomepageDataReport",
            name: "HomepageDataReport",
            component: () =>
              import(
                "@/views/content/medicalConference/dataAnalysis/HomepageDataReport"
              ),
          },
          {
            // 首页数据
            path: "/test",
            name: "test",
            component: () =>
              import("@/views/content/medicalConference/dataAnalysis/test"),
          },
        ],
      },
      //新建九宫格会议界面
      {
        path: "/meeting",
        name: "Meeting",
        component: () => import("@/views/content/medicalConference/Meeting"),
      },
      {
        //首页样式管理
        path: "/pageManagement",
        name: "pageManagement",
        component: () =>
          import("@/views/content/medicalConference/pageManagement"),
      },
      //直播会议管理
      {
        path: "/liveStreaming",
        name: "LiveStreaming",
        component: () => import("@/views/content/liveManages/LiveStreaming"),
      },
      //系列直播会议管理
      {
        path: "/liveConferenceSeries",
        name: "LiveConferenceSeries",
        component: () =>
          import("@/views/content/liveManages/LiveConferenceSeries"),
      },
      //医学会议管理--首页图标管理
      {
        path: "/photoCommander",
        name: "photoCommander",
        component: () =>
          import("@/views/content/medicalConference/photoCommander"),
      },
      {
        path: "/featureManagement",
        name: "featureManagement",
        component: () =>
          import("@/views/content/medicalConference/featureManagement"),
      },
      {
        path: "/featureDHZB",
        name: "FeatureDHZB",
        component: () =>
          import("@/views/content/medicalConference/FeatureDHZB"),
      },
      {
        path: "/featureDuration",
        name: "FeatureDuration",
        component: () =>
          import("@/views/content/medicalConference/FeatureDuration"),
      },
      {
        // 会议分类管理
        path: "/columnmanagement",
        name: "columnmanagement",
        component: () =>
          import("../views/content/columnmangen/columnManagement"),
      },
      {
        // 图片素材分类管理
        path: "/pictureManagement",
        name: "pictureManagement",
        component: () =>
          import("../views/content/columnmangen/pictureManagement"),
      },
      {
        // 外链素材分类管理
        path: "/linkManagement",
        name: "linkManagement",
        component: () => import("../views/content/columnmangen/linkManagement"),
      },
      {
        // 视频
        path: "/videoManagement",
        name: "videoManagement",
        component: () =>
          import("../views/content/columnmangen/videoManagement"),
      },

      {
        // 黑名单
        path: "/blackList",
        name: "blackList",
        component: () => import("../views/content/medicalConference/blackList"),
      },
      {
        // 我的客户
        path: "/myClient",
        name: "myClient",
        component: () => import("../views/content/customerManagement/myClient"),
      },
      {
        // 客户服务管理
        path: "/CustomerServiceManagement",
        name: "CustomerServiceManagement",
        component: () => import("../views/content/customerManagement/CustomerServiceManagement"),
      },
      {
        // 客户服务管理
        path: "/ConsultingManagement",
        name: "ConsultingManagement",
        component: () => import("../views/content/customerManagement/ConsultingManagement"),
      },
      {
        //禁言记录
        path: "/prohibition",
        name: "prohibition",
        component: () =>
          import("../views/content/medicalConference/prohibition"),
      },
      {
        // 图片素材管理
        path: "/pictureControl",
        name: "pictureControl",
        component: () =>
          import("@/views/content/medicalConference/pictureControl"),
      },
      {
        // 测试
        path: "/ElectronicMaterial",
        name: "ElectronicMaterial",
        component: () =>
          import("@/components/ManagementOfMeetings/ElectronicMaterial"),
      },
      {
        // 我的收藏图片素材管理
        path: "/pictureCollect",
        name: "PictureCollect",
        component: () =>
          import("@/views/content/medicalConference/PictureCollect"),
      },
      {
        // 视频素材管理
        path: "/videoControl",
        name: "videoControl",
        component: () =>
          import("@/views/content/medicalConference/videoControl"),
      },
      {
        // 外链
        path: "/linkControl",
        name: "linkControl",
        component: () =>
          import("@/views/content/medicalConference/linkControl"),
      },
      {
        path: "/liveRoom",
        // name: "LiveRoom",
        component: () => import("@/views/content/liveManages/LiveRoom"),
        children: [
          {
            // 介绍
            path: "introduce",
            name: "Introduce",
            component: () => import("@/components/LiveRoomFunModule/Introduce"),
          },
          {
            // 讨论
            path: "discussion",
            name: "Discussion",
            component: () =>
              import("@/components/LiveRoomFunModule/Discussion"),
          },
          {
            // 回放
            path: "playback",
            name: "Playback",
            component: () => import("@/components/LiveRoomFunModule/Playback"),
          },
          {
            // 云展厅
            path: "exhibitionHall",
            name: "ExhibitionHall",
            component: () =>
              import("@/components/LiveRoomFunModule/ExhibitionHall"),
          },
          {
            // 企业海报
            path: "corporatePoster",
            name: "CorporatePoster",
            component: () =>
              import("@/components/LiveRoomFunModule/CorporatePoster"),
          },
          {
            // 广告通知-开场广告
            path: "openingAdvertisement",
            name: "OpeningAdvertisement",

            component: () =>
              import("@/components/LiveRoomFunModule/OpeningAdvertisement"),
          },
          {
            // 广告通知-通知公告
            path: "notificationAnnouncement",
            name: "NotificationAnnouncement",
            component: () =>
              import("@/components/LiveRoomFunModule/NotificationAnnouncement"),
          },
          {
            // 高级功能设置-悬浮功能
            path: "suspensionFunction",
            name: "SuspensionFunction",
            component: () =>
              import("@/components/LiveRoomFunModule/SuspensionFunction"),
          },
          // //超级管理员----首页图标管理
          // {
          //   path: "/photoSuperAdmin",
          //   name: "photoSuperAdmin",
          //   component: () =>
          //     import("@/views/content/medicalConference/photoSuperAdmin"),
          // },
          // {
          //   // 首页样式管理
          //   path: "/pageControl",
          //   name: "pageControl",
          //   component: () =>
          //     import("@/views/content/medicalConference/pageControl"),
          // },
        ],
      },
      {
        // 账号管理
        path: "/accountManagement",
        name: "AccountManagement",
        component: () => import("@/views/content/account/AccountManagement"),
      },
      {
        // 子账号管理
        path: "/childAccountManage",
        name: "childAccountManage",
        component: () => import("@/views/content/account/childAccountManage"),
      },
      {
        // 订单管理
        path: "/orderManagement",
        name: "OrderManagement",
        component: () => import("@/views/content/account/OrderManagement"),
      },
      {
        // 发票记录管理
        path: "/invoiceRecord",
        name: "InvoiceRecord",
        component: () => import("@/views/content/account/InvoiceRecord"),
      },
      {
        // 合同申请
        path: "/contractApplication",
        name: "ContractApplication",
        component: () => import("@/views/content/account/ContractApplication"),
      },
      {
        // 订购服务
        path: "/orderServices",
        name: "OrderServices",
        component: () => import("@/views/content/account/OrderServices"),
      },
      {
        // 服务咨询管理
        path: "/serviceConsultingManage",
        name: "serviceConsultingManage",
        component: () => import("@/views/content/serviceConsultingManage/serviceConsultingManage"),
      },
      {
        // 会务管理----任务模版设置
        path: "/templateManagement",
        name: "templateManagement",
        component: () => import("@/views/content/conferenceManagement/template.vue"),
      },
      {
         //会务管理---查看模版
         path:'/lookTemplate',
         name:'lookTemplate',
         component: () => import("@/views/content/conferenceManagement/lookTemplate.vue"),
      },
      {
        //会务管理---编辑模版
        path:'/setupTemplate',
        name:'setupTemplate',
        component: () => import("@/views/content/conferenceManagement/setupTemplate.vue"),
      },
      {
        // 会议人员管理--内部人员
        path:'/InternalPersonManagement',
        name:'InternalPersonManagement',
        component: () => import("@/views/content/meetingExecutionPeopleManagement/InternalPersonManagement"),
      },
      {
        // 会议人员管理--供应商
        path:'/SuppliersManagement',
        name:'SuppliersManagement',
        component: () => import("@/views/content/meetingExecutionPeopleManagement/SuppliersManagement"),
      },
      {
        // 会议人员管理--志愿者
        path:'/volunteerManagement',
        name:'volunteerManagement',
        component: () => import("@/views/content/meetingExecutionPeopleManagement/volunteerManagement"),
      },
      {
        //会务管理---会议管理
        path:'/conferenceManage',
        name:'ConferenceManage',
        component: () => import("@/views/content/conferenceManagement/ConferenceManage.vue"),
      },
      {
        //会议管理
        path:'/meetingManagementClub',
        name:'meetingManagementClub',
        component: () => import("@/views/content/conferenceManagement/managementOfMeetings.vue"),
      },
      {
        //vip专家管理
        path:'/specialistManage',
        name:'specialistManage',
        component: () => import("@/views/content/conferenceManagement/SpecialistManage.vue"),
      },
      {
        //支付设置
        path:'/PaymentSettings',
        name:'paymentSetting',
        component: () => import("@/views/content/PaymentSettings/PaymentSettings.vue"),
      },
      {
        //支付设置
        path:'/homepageTemplate',
        name:'homepageTemplate',
        component: () => import("@/views/content/medicalConference/homepageTemplate.vue"),
      },
    ],
  },
  // {
  //   // 会议管理root
  //   path: "/managementOfMeetingsRoot",
  //   component: managementOfMeetingsRoot,
  //   children: [
  //     {
  //       //会务管理---会议管理
  //       path:'/conferenceManage',
  //       name:'ConferenceManage',
  //       component: () => import("@/views/content/conferenceManagement/ConferenceManage.vue"),
  //     },
  //     {
  //       //会议管理
  //       path:'/managementOfMeetings',
  //       name:'managementOfMeetings',
  //       component: () => import("@/views/content/conferenceManagement/managementOfMeetings.vue"),
  //     },
  //     // {
  //     //   //会议管理---任务分配
  //     //   path:'/taskAllocation',
  //     //   name:'taskAllocation',
  //     //   component: () => import("@/components/ManagementOfMeetings/taskAllocation"),
  //     // },
  //   ]
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginYC"),
  },
  // 用户协议
  // {
  //   path: "/userAgreement",
  //   name: "userAgreement",
  //   component: () => import("@/components/login/userAgreement"),
  //   meta:{
  //     title:'用户协议'
  //   }
  // },
  {
    path: "/testWeb",
    name: "test",
    component: () => import("@/views/Test"),
  },
];

// const  scrollBehavior = (to, from, savedPosition) => {
//   if (savedPosition && to.meta.keepAlive) {
//     return savedPosition;
//   }
//   return { x: 0, y:0 };
// }

const router = new VueRouter({
  routes,
  // scrollBehavior,
  mode: "history",
});

export default router;

router.beforeEach((to, from, next) => {
  //如果是登录页面路径，就直接next()
  if (window.localStorage.getItem("yichi_userInfo")) {
    //获取本地存储信息，并转换为Json格式
    const userData = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    //判断有没有token
    if (
      userData.token !== "" &&
      userData.token !== undefined &&
      userData.token !== null
    ) {
      //返回上一层路由
      next(-1);
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});
