import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueClipboards from "vue-clipboard2";
import VueParticles from "vue-particles";
import "./assets/DINfont.css";
import "./common/normolize.css";
Vue.config.productionTip = false;

import TcVod from "vod-js-sdk-v6";
Vue.prototype.$TcVod = TcVod;

Vue.use(Antd);
Vue.use(VueClipboards);
Vue.use(VueParticles);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
