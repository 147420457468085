var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"dialogStyle":{ 'top': '8%',},"width":"900px","visible":_vm.visible,"footer":false},on:{"cancel":_vm.handleCancel}},[(_vm.notReadAllCountXT)?_c('div',[_c('div',{staticClass:"messageNotifi"},[_c('div',{staticClass:"titleInfo"},[_c('a-icon',{staticStyle:{"font-size":"30px","color":"#EA9518","margin-right":"5px"},attrs:{"type":"info-circle","theme":"filled"}}),_vm._v(" "),_c('span',[_vm._v("消息通知")])],1),(_vm.NotReadAllCountList.content)?_c('div',{staticStyle:{"margin-left":"41px","color":"#333333","padding-top":"60px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.NotReadAllCountList.content)}})]):_vm._e(),(_vm.NotReadAllCountList.annex)?_c('div',{staticClass:"paperClip"},[_c('div',[_c('a-icon',{staticStyle:{"font-size":"25px","padding-right":"10px"},attrs:{"type":"paper-clip"}}),_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("附件")])],1),_vm._l((_vm.NotReadAllCountList.annex),function(item,index){return _c('div',{key:index,staticClass:"contentPdf"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('a',{on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v("预览")])])])})],2):_vm._e()]),_c('div',{style:({
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          display:'flex',
          height:'50px',
          justifyContent:'space-between'
        })},[_c('div',{staticStyle:{"width":"33%"}}),_c('div',{staticStyle:{"width":"34%","text-align":"center"}},[_vm._v(" 第 "),_c('span',{staticStyle:{"color":"#1890FF"}},[_vm._v(_vm._s(_vm.pageNo))]),_vm._v(" 篇，共 "+_vm._s(_vm.notReadAllCountXT)+" 篇 ")]),_c('div',{staticStyle:{"width":"33%"}},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v(" 稍后查看 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.markRead}},[_vm._v(" 标记已读 ")])],1)])]):_c('div',[_c('div',{staticClass:"messageNotifi"},[_c('div',{staticClass:"titleInfo"},[_c('a-icon',{staticStyle:{"font-size":"30px","color":"#EA9518","margin-right":"5px"},attrs:{"type":"info-circle","theme":"filled"}}),_vm._v(" "),_c('span',[_vm._v("消息通知(全部)")])],1),(_vm.allCount === 0)?_c('div',{staticStyle:{"margin-top":"20%","text-align":"center","color":"#9c9b9b"}},[_c('admin_con',{staticStyle:{"font-size":"100px","margin-bottom":"10px"},attrs:{"type":"icon-zanwutongzhi1"}}),_c('br'),_vm._v(" 暂无通知 ")],1):_vm._e(),(_vm.NotReadAllCountList.content)?_c('div',{staticStyle:{"margin-left":"41px","color":"#333333","padding-top":"60px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.NotReadAllCountList.content)}})]):_vm._e(),(_vm.NotReadAllCountList.annex)?_c('div',{staticClass:"paperClip"},[_c('div',[_c('a-icon',{staticStyle:{"font-size":"25px","padding-right":"10px"},attrs:{"type":"paper-clip"}}),_c('span',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("附件")])],1),_vm._l((_vm.NotReadAllCountList.annex),function(item,index){return _c('div',{key:index,staticClass:"contentPdf"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('a',{on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v("预览")])])])})],2):_vm._e()]),_c('div',{style:({
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          display:'flex',
          height:'50px',
          justifyContent:'space-between'
        })},[_c('div',{staticStyle:{"width":"33%"}},[_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"disabled":_vm.pageNo === 1},on:{"click":_vm.previousPage}},[_vm._v(" 上一页 ")])],1),_c('div',{staticStyle:{"width":"34%","text-align":"center"}},[_vm._v(" 第 "),_c('span',{staticStyle:{"color":"#1890FF"}},[_vm._v(_vm._s(_vm.pageNo))]),_vm._v(" 篇，共 "+_vm._s(_vm.allCount)+" 篇 ")]),_c('div',{staticStyle:{"width":"33%","display":"flex","justify-content":"left"}},[_c('a-button',{attrs:{"disabled":_vm.pageNo === _vm.allCount},on:{"click":_vm.nextPage}},[_vm._v(" 下一页 ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }