import { get, put, post, del } from "./base";

// const user = localStorage.getItem("yichi_userInfo") ? JSON.parse(localStorage.getItem("yichi_userInfo")) : {}
// const token = user.id

// 首页样式管理
export function getPage(data) {
  return get("/yun/yichi_homeTemp", data);
}

// 数据类型
// 获取数据类型
export function getManagement(data) {
  return get("/api/admin/convention/columns", data);
}
// 新增数据类型
export function AddManagement(data) {
  return post("/api/admin/convention/column", data);
}
// 修改数据类型
export function updatedManagement(data) {
  return put("/api/admin/convention/column", data);
}
// 移动
export function moveManagement(type, id, columnType) {
  return put(`/api/admin/convention/moveColum/${type}/${id}/${columnType}`);
}

// 超级管理员
// 获取样式
export function getAdminPage(data) {
  return get("/yun/yichi_homeTemp", data);
}
// 新增样式
export function addPage(data) {
  return post("/yun/yichi_homeTemp", data);
}

// 获取模板样式
export function getPageTemplate() {
  return get("/yun/hpTemp");
}
// 获取图标模板样式
export function getIconTemplate(data) {
  return get("/convention/icontemp", data);
}
// 判断指定图标模版
export function getIsopen(id) {
  return get(`/convention/icontemp?id=${id}`);
}
//编辑样式
export function editPagetemplate(data) {
  return put("/yun/yichi_homeTemp", data);
}
// 删除样式
export function delPagetemplate(id) {
  return del(`/yun/yichi_homeTemp/${id}`);
}
//获取签名
export function getSignature() {
  return get("/yun/getUgcUploadSign");
}
//获取视频url
export function getVideoLink(videoId) {
  return get("/yun/videoLink", { videoId });
}
// 添加图标模板
export function addIcontemplate(data) {
  return post("/convention/icontemp", data);
}
// 批量插入图标
export function addIcon(data) {
  return post("/convention/bach_icon", data);
}

// 获取单个图标
export function getOneicon(id) {
  return get(`/convention/icontemp?id=${id}`);
}
// 获取单个素材图标
export function getIcon(id) {
  return get(`/convention/icon?icontempId=${id}`);
}
// 关联列表图标
export function correlation(data) {
  return put("/yun/yichi_homeTemp", data);
}

// 素材管理
// 获取图片
export function getPicture(data) {
  return get("/material/picture_material", data);
}
// 修改图片
export function changePicture(data) {
  return put("/material/picture_material", data);
}
// 删除图片
export function delPicture(id) {
  return del(`/material/picture_material/${id}`);
}
// 添加图片
export function InsertImg(data) {
  return post("/material/picture_material", data);
}

// 获取视频
export function getVideo(data) {
  return get("/yichi/videoMaterial", data);
}
// 批量上传视频
export function InsertVideo(data) {
  return post("/yichi/videoMaterials", data);
}
// 删除视频素材
export function delVideo(id) {
  return del(`/yichi/videoMaterial/${id}`);
}
// 修改视频素材
export function changeVideo(data) {
  return put("/yichi/videoMaterial", data);
}

// 获取外链
export function getLink(data) {
  return get("/material/link_material", data);
}
// 删除
export function delLink(id) {
  return del(`/material/link_material/${id}`);
}
// 修改
export function changeLink(data) {
  return put("/material/link_material", data);
}
// 添加
export function InsertLink(data) {
  return post("/material/link_material", data);
}

// 广告通知——通知公告
export function getAdvertiseNotice(data) {
  return get("/api/admin/advertiseNotice", data);
}
export function insertAdvertiseNotice(data) {
  return post("/api/admin/advertiseNotice", data);
}
export function updateAdvertiseNotice(data) {
  return put("/api/admin/advertiseNotice", data);
}
export function delAdvertiseNotice(id) {
  return del(`/api/admin/advertiseNotice/${id}`);
}

// 菜单管理
// 获取菜单
export function getMenu(data) {
  return get("/api/admin/menu", data);
}
// 新增菜单
export function addMenu(data) {
  return post("/api/admin/menu", data);
}
// 删除菜单
export function delMenu(id) {
  return del(`/api/admin/menu/${id}`);
}
// 一级菜单上移下移
export function moveMenu(type, id) {
  return put(`/api/admin/moveMenu/${type}/${id}`);
}
// 二级菜单上移下移
export function moveZmenu(type, id, parentId) {
  return put(`/api/admin/moveMenu/${type}/${id}/${parentId}`);
}
// 根据id修改菜单

export function editMenu(data) {
  return put("/api/admin/menu", data);
}

// 安全设置
export function getSafe() {
  return get("/api/admin/settingMemberInfo");
}
// 判断验证码是否正确
export function smsRight(data) {
  return get("/api/admin/smsCodeIsCorrect", data);
}
// 设置登录密码
export function editPsd(data) {
  return put("/api/admin/settingPassWord", data);
}
//发送邮箱验证码
export function getEmailCode(email) {
  return post(`/api/admin/sendEmailCode?email=${email}`);
}
// 绑定邮箱
export function updateEmail(code, email) {
  return put(`/api/admin/updMemberEmailByCode?code=${code}&email=${email}`);
}
// 新手机号发送的验证码
export function NewSendCode(data) {
  return post("/api/admin/adminUpdLogTelSendSMS", data);
}
// 修改登录手机号
export function updateTel(data) {
  return put("/api/admin/settingLoginTel", data);
}

// 邮寄地址
export function getAddressList(userId) {
  return get(`/api/admin/mailingAddress/${userId}`);
}
// 设置为默认地址
export function mailingAddress(id) {
  return put(`/api/admin/mailingAddress/${id}`);
}
// 删除邮寄地址
export function delAddress(id) {
  return del(`/api/admin/mailingAddress/${id}`);
}
// 添加邮寄地址
export function newAddress(data) {
  return post("/api/admin/mailingAddress", data);
}
// 修改邮寄地址
export function editAddress(data) {
  return put("/api/admin/mailingAddress", data);
}
// 获取同公司子账号
export function getChildAccount(companyId,data) {
  return get(`/api/admin/childAccount?companyId=${companyId}`,data);
}
// 添加子账号
export function addChild(data) {
  return post("/api/admin/childAccount", data);
}
// 发送验证码
export function getSMS(data) {
  return post("/api/admin/childAccountSms", data);
}
// 删除子账号
export function delChild(id) {
  return del(`/api/admin/childAccount/${id}`);
}

