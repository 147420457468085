<template>
  <!-- 商户菜单管理 -->
  <div class="manageSider">
    <div class="logo">
      <div
        @click="
          () => {
            this.$router.push('/meetingManagement');
            this.select = '[0]';
          }
        "
        style="cursor: pointer"
      >
        <img src="@/assets/yich_logo.png" style="width: 90px" />
      </div>
    </div>
    <div class="siderMenu">
      <a-menu
        mode="inline"
        style="border-top: 1px solid #f5f5f5; border-right: none"
        @openChange="onOpenChange"
        :selectedKeys="[$route.path]"
        v-model="select"
        :open-keys="openKeys"
      >
        <a-sub-menu
          style="margin-left: 12px"
          :key="menu.id"
          v-for="menu in menus"
        >
          <span slot="title">
<!--            <img-->
<!--              style="-->
<!--                width: 25px;-->
<!--                height: 25px;-->
<!--                margin-right: 8px;-->
<!--                margin-left: -10px;-->
<!--              "-->
<!--              :src="menu.icon === '' ? img : menu.icon"-->
<!--              alt=""-->
<!--            />-->
<!--            <span>{{ menu.name }}</span>-->
            <div style="display: flex;justify-content: left">
              <div class="titleIcon">
                {{menu.name.slice(0,1)}}
              </div>
              <span>{{ menu.name }}</span>
            </div>
          </span>
          <a-menu-item
            :key="menuChildren.path"
            v-for="menuChildren in menu.item"
            @click="menuTitle(menu.name, menuChildren.name)"
          >
            <router-link :to="menuChildren.path">
              <span style="margin-left: -1px">{{ menuChildren.name }}</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { manageMenus } from "@/service/menu_api";
import { getMenu } from "@/service/columnmanageapi";
export default {
  name: "ManageSider",
  props: ["collapsed"],
  data() {
    return {
      menus: [],
      select: ["0"],
      openKeys: [],
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      object: {},
    };
  },
  async created() {
    await this.getMenus();
    await this.change_select();
    const user = JSON.parse(localStorage.getItem("yichi_userInfo"));
    //1是个人认证
    // if (user.memberStatus == 1) {
    //   this.menus.forEach((item, index) => {
    //     if (item.name == "账号管理") {
    //       item.item.splice(1);
    //     }
    //   });
    // }
  },
  computed: {
    liveHistory() {
      return this.$store.state.liveMeetHistory;
    },
  },
  mounted() {},
  watch: {
    $route(to, from) {
      // console.log('123',to.name, from.name)
      // this.change_select();
      let path = this.$route.path;
      let select = [];
      select.push(path);
      this.select = select;
    },
  },
  methods: {
    async change_select() {
      let path = this.$route.path;
      let select = [];
      select.push(path);
      this.select = select;
      console.log('select',this.select);
      let menuData = this.menus;
      // console.log(menuData);
      for (const menuDataKey in menuData) {

        for (const itemKey in menuData[menuDataKey].item) {

          let myList = [];

          if (menuData[menuDataKey].item[itemKey].path === path) {

            myList.push(menuData[menuDataKey].item[itemKey].parentId);
            this.openKeys = myList;
            break;

          }
        }
      }
      console.log('openKeys',this.openKeys)
    },
    //获取菜单数据
    async getMenus() {
      let data = {
        isOpen: 1,
        isStaff: 1,
      };
      const response = await getMenu(data);
      if (response.code === 0) {
        this.menus = response.data;

        // this.menus.forEach((element) => {
        //   this.openKeys.push(element.id);
        // });
      }
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }

    },
    menuTitle(FTitle, STitle) {
      this.$store.dispatch("manageHeaderTitleFTitle", FTitle);
      this.$store.dispatch("manageHeaderTitleSTitle", STitle);
      this.$store.dispatch("isShowNav", true); //恢复导航栏
      // 判断是菜单点击进入
      this.liveHistory.isRouter = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.siderMenu li {
  //padding-left: 20px !important;
}
.logo {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleIcon{
  width: 25px;
  height: 25px;
  border-radius: 10px;
  margin-top: 6px;
  margin-right: 5px;
  background: linear-gradient(to right bottom, #FFB97D,#FE4259);
  color: #F7F9FA;
  text-align: center;
  font-size: 12px;
  line-height: 25px;
}
</style>
