var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"title"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"messageNat"},[_c('div',{staticClass:"systemNotice",on:{"click":function($event){return _vm.openMessageNotifi('DJJ')}}},[_c('admin_con',{staticStyle:{"font-size":"30px","margin-right":"35px"},attrs:{"type":"icon-liwu"}})],1),_c('div',{staticClass:"systemNotice",on:{"click":function($event){return _vm.openMessageNotifi('XTTZ')}}},[_c('div',{staticClass:"icon"},[_c('a-icon',{staticStyle:{"font-size":"30px"},attrs:{"type":"mail"}}),(_vm.notReadAllCountXT)?_c('div',{staticClass:"numberIcon"},[_vm._v(_vm._s(_vm.notReadAllCountXT))]):_vm._e()],1),_c('div',{staticStyle:{"color":"#333333","margin-left":"10px"}},[_vm._v("系统公告"),(_vm.notReadAllCountXT)?_c('span',[_vm._v("("+_vm._s(_vm.notReadAllCountXT)+")")]):_vm._e()])])]),_c('a-popover',{attrs:{"trigger":"click","placement":"bottomRight"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.userInfoList.memberStatus === 2 ? _vm.userInfoList.companyName : _vm.userInfoList.contactsName)+" ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticStyle:{"height":"100px","color":"#5a5a5a"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openRZ(_vm.userInfoList)}}},[_c('a-icon',{attrs:{"type":"safety"}}),_vm._v(" 实名认证（ "),_c('span',{style:({
                    color:
                      _vm.userInfoList.companyStatus === 0
                        ? ''
                        : _vm.userInfoList.companyStatus === 1
                        ? '#45a5e6'
                        : _vm.userInfoList.companyStatus === 2
                        ? 'red'
                        : _vm.userInfoList.companyStatus === 3 || _vm.userInfoList.companyStatus === 4 ? '#35D57E':'',
                  })},[_vm._v(" "+_vm._s(_vm.userInfoList.companyStatus === 0 ? "未认证" : _vm.userInfoList.companyStatus === 1 ? "待审核" : _vm.userInfoList.companyStatus === 2 ? "未通过" : _vm.userInfoList.companyStatus === 3 || _vm.userInfoList.companyStatus === 4 ? "已认证":'')+" ")]),_vm._v("） ")],1),_c('div',{staticStyle:{"margin-top":"10px","cursor":"pointer"},on:{"click":_vm.linkBtn}},[_c('a-icon',{staticStyle:{"font-size":"14px"},attrs:{"type":"user"}}),_vm._v(" 账号信息 ")],1)]),_c('div',{staticStyle:{"height":"30px","line-height":"30px","color":"#5a5a5a","cursor":"pointer"}},[_c('a-popconfirm',{attrs:{"title":"是否确认退出登录?","ok-text":"是","cancel-text":"否"},on:{"confirm":_vm.log_out}},[_c('a-icon',{attrs:{"type":"logout"}}),_vm._v(" 退出登录 ")],1)],1)]),_c('div',{staticClass:"headerSty",style:({
              backgroundColor:_vm.userInfoList.memberStatus === 0 ? '#b1afaf' : '#1890FF',
            }),on:{"click":_vm.openPopover}},[_vm._v(" "+_vm._s(_vm.user_name)+" ")])]),_c('div',{staticStyle:{"margin-top":"7px","margin-left":"5px","margin-right":"20px"},on:{"click":_vm.openPopover}},[_c('a-icon',{staticStyle:{"font-size":"12px"},attrs:{"type":"caret-down"}})],1),(_vm.systemText)?_c('div',{staticStyle:{"margin-top":"7px","margin-right":"20px"}},[_vm._v(" "+_vm._s(_vm.systemText)+" ")]):_vm._e()],1)]),_c('RealNameAuthentication',{attrs:{"visible":_vm.visibleAuthentication,"isShow":_vm.isShow,"jurisdiction":_vm.jurisdiction},on:{"closeMainRealNameAuth":_vm.closeMain}}),_c('UnderReview',{attrs:{"visible":_vm.visibleUnderReview},on:{"closeMain":_vm.closeMains}}),(_vm.visibleMessageNotifi)?_c('MessageNotifi',{attrs:{"visible":_vm.visibleMessageNotifi},on:{"closeMainMessageNotifi":_vm.closeMainMessageNotifi}}):_vm._e(),(_vm.visibleGiftCard)?_c('GiftCard',{attrs:{"visible":_vm.visibleGiftCard},on:{"closeMainGiftCard":_vm.closeMainGiftCard}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }