<template>
<div>
<!--  代金卷  -->
  <a-modal
      :dialogStyle="{ 'top': '8%',}"
      width="920px"
      :title="title"
      :visible="visible"
      :footer="false"
      @cancel="handleCancel"
  >
    <a-spin :spinning="spinning">
    <div class="GiftCard">
<!--      代金卷-->
      <div v-if="select === '1'" class="GiftCard-view">
        <div style="text-align: right;margin-bottom: 15px"><a @click="openGift('2')">查看我的代金券>>></a></div>

        <a-row :gutter="16">
          <a-col :span="8" v-for="(item,index) in receiveCouponList">
            <div class="titleImg" :key="index">
              <img class="img" src="../../assets/paidImg/couponY.png" alt="">
              <div class="title-coupon-div">
                <div style="width: 110px;text-align: center">
                  <div><span class="couponMoneyIcon">￥</span><span class="money">{{item.favorablePrice}}</span></div>
                  <!--                <div class="mark" :style="{color: getColors(index)}">满300使用</div>-->
                  <div class="mark" :style="{color: '#e28700'}">满{{item.thresholdPrice}}使用</div>
                  <div class="markSY" v-if="remainingDays(item.endTime) > 0 && remainingDays(item.endTime) <=7">活动剩余{{remainingDays(item.endTime)}}天过期</div>
                </div>
                <div style="width: 170px;font-size: 12px;text-align: center;">
                  <div class="coupon-right-title">{{item.couponName}}</div>
                  <div class="coupon-right-time" :style="{color: '#e28700'}">{{item.beginTime}}-{{item.endTime}}</div>
                  <div class="coupon-right-tag">
                    <a-tag color="#ffffff" style="margin-bottom: 5px" v-for="(tag,index) in item.label" :key="index">
                      <span class="tag-font" :style="{color: '#e28700'}">{{tag}}</span>
                    </a-tag>
                  </div>
                  <div class="getGift" @click="immediateClaimBtn(item.id)">
                    立即领取
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>

        <div v-if="receiveCouponList.length === 0" style="margin-top: 20%;text-align: center;color: #9c9b9b">
          <admin_con style="font-size: 100px;margin-bottom: 10px" type="icon-zanwuyouhuijuan"/>
          <br/>
          暂无优惠卷
        </div>

<!--        <div class="title">-->
<!--          <div class="titleImg" v-for="(item,index) in 3" :key="index">-->
<!--            <img class="img" src="../../assets/paidImg/couponY.png" alt="">-->
<!--            <div class="title-coupon-div">-->
<!--              <div style="width: 90px;">-->
<!--                <div><span class="couponMoneyIcon">￥</span><span class="money">50</span></div>-->
<!--                &lt;!&ndash;                <div class="mark" :style="{color: getColors(index)}">满300使用</div>&ndash;&gt;-->
<!--                <div class="mark" :style="{color: '#e28700'}">满300使用</div>-->
<!--              </div>-->
<!--              <div style="width: 165px;font-size: 12px;text-align: center;">-->
<!--                <div class="coupon-right-title">全场通用优惠卷</div>-->
<!--                <div class="coupon-right-time" :style="{color: '#e28700'}">2024/06/06-2024/06/09</div>-->
<!--                <div class="coupon-right-tag">-->
<!--                  <a-tag color="#ffffff" style="margin-bottom: 5px"><span class="tag-font" :style="{color: '#e28700'}">活动专属</span></a-tag>-->
<!--                  <a-tag color="#ffffff" style="margin-bottom: 5px"><span class="tag-font" :style="{color: '#e28700'}">不可叠加活动</span></a-tag>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
<!--      我的代金卷-->
      <div v-if="select === '2'" class="GiftCard-view">
        <div class="GiftCard-view-top">
          <div>
<!--            未使用传no_use 已使用传use 已过期传overdue-->
            <a-tabs v-model="tabsVal" @change="callback" :size="size">
              <a-tab-pane key="no_use" tab="未使用">
              </a-tab-pane>
              <a-tab-pane key="use" tab="已使用" force-render>
              </a-tab-pane>
              <a-tab-pane key="overdue" tab="已过期">
              </a-tab-pane>
            </a-tabs>
          </div>
          <a @click="openGift('1')">活动中心>>></a>
        </div>

        <a-row :gutter="16">
          <a-col :span="8" v-for="(item,index) in receiveCoupon" :key="index">
            <div class="titleImg" :key="index">
              <img v-if="tabsVal === 'overdue'" class="img" src="../../assets/paidImg/noCoupon.png" alt="">
              <img v-else class="img" src="../../assets/paidImg/couponY.png" alt="">

              <div class="title-coupon-div">
                <div style="width: 110px;text-align: center">
                  <div><span class="couponMoneyIcon">￥</span><span class="money">{{item.favorablePrice}}</span></div>
                  <!--                <div class="mark" :style="{color: getColors(index)}">满300使用</div>-->
                  <div class="mark" :style="{color: tabsVal === 'overdue' ? '#666666': '#e28700'}">满{{item.thresholdPrice}}使用</div>
                  <div class="markSY">
                    <span v-if="tabsVal === 'no_use'">
                      <span v-if="remainingDays(item.overdueTime) > 0 && remainingDays(item.overdueTime) <= 7">剩余{{remainingDays(item.overdueTime)}}天过期</span>
                    </span>
                    <span v-if="tabsVal === 'use'">已使用</span>
                    <span v-if="tabsVal === 'overdue'">已过期</span>
                  </div>
                </div>
                <div style="width: 170px;font-size: 12px;text-align: center;">
                  <div class="coupon-right-title" style="padding-top: 8px;padding-bottom: 5px">{{item.couponName}}</div>
                  <div class="coupon-right-time" :style="{color: tabsVal === 'overdue' ? '#666666': '#e28700'}">{{item.beginTime}}-{{item.endTime}}</div>
                  <div class="coupon-right-tag">
                    <a-tag color="#ffffff" style="margin-bottom: 5px" v-for="(tag,index) in item.label" :key="index">
                      <span class="tag-font" :style="{color: tabsVal === 'overdue' ? '#666666': '#e28700'}">{{tag}}</span>
                    </a-tag>
                  </div>
<!--                  <div class="getGift">-->
<!--                    立即领取-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </a-col>
        </a-row>

        <div v-if="receiveCoupon.length === 0" style="margin-top: 20%;text-align: center;color: #9c9b9b">
          <admin_con style="font-size: 100px;margin-bottom: 10px" type="icon-zanwuyouhuijuan"/>
          <br/>
          暂无{{tabsVal === 'no_use' ? '未使用的':tabsVal === 'use' ? '已使用的':tabsVal === 'overdue' ? '已过期的':''}}优惠卷
        </div>

        <!--        <div class="title">-->
        <!--          <div class="titleImg" v-for="(item,index) in 3" :key="index">-->
        <!--            <img class="img" src="../../assets/paidImg/couponY.png" alt="">-->
        <!--            <div class="title-coupon-div">-->
        <!--              <div style="width: 90px;">-->
        <!--                <div><span class="couponMoneyIcon">￥</span><span class="money">50</span></div>-->
        <!--                &lt;!&ndash;                <div class="mark" :style="{color: getColors(index)}">满300使用</div>&ndash;&gt;-->
        <!--                <div class="mark" :style="{color: '#e28700'}">满300使用</div>-->
        <!--              </div>-->
        <!--              <div style="width: 165px;font-size: 12px;text-align: center;">-->
        <!--                <div class="coupon-right-title">全场通用优惠卷</div>-->
        <!--                <div class="coupon-right-time" :style="{color: '#e28700'}">2024/06/06-2024/06/09</div>-->
        <!--                <div class="coupon-right-tag">-->
        <!--                  <a-tag color="#ffffff" style="margin-bottom: 5px"><span class="tag-font" :style="{color: '#e28700'}">活动专属</span></a-tag>-->
        <!--                  <a-tag color="#ffffff" style="margin-bottom: 5px"><span class="tag-font" :style="{color: '#e28700'}">不可叠加活动</span></a-tag>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    </a-spin>
  </a-modal>
</div>
</template>

<script>
import {postReceiveByDacId, selectCanReceiveCouponList, selectReceiveCouponList} from "@/service/authentication";
import {admin_icon} from "@/utils/myIcon";
import {setChannel} from "@/service/base";
export default {
  name: "MessageNotifi",
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components:{admin_con: admin_icon,},
  data(){
    return{
      title:'代金卷',
      size: 'small',
      tabsVal: 'no_use',
      select: '1',
      receiveCouponList:[], //获取用户可领取的优惠券
      receiveCoupon:[], //获取用户已领取的优惠券
      spinning:false,
    }
  },
  computed:{

  },
  created() {
    this.selectCanReceiveCoupon();
  },
  methods:{
    //立即领取
    async immediateClaimBtn(id) {
      await setChannel('GiftCenter');
      let data = {
        id:id
      }
      const response = await postReceiveByDacId(data);
      if (response.code === 200) {
        await this.selectCanReceiveCoupon();
      } else {
        this.$message.warning(response.message);
      }
    },
    remainingDays(startDate) {
      if (!startDate) return null; // 如果没有选择日期，返回 null
      const today = new Date(); // 获取当前日期
      const start = new Date(startDate); // 将输入的日期转换为 Date 对象
      const timeDiff = start - today; // 计算时间差（毫秒）
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // 转换为天数
      return daysDiff+1; // 返回剩余天数
    },
    //获取用户可领取的优惠券 select === '1'
    async selectCanReceiveCoupon() {
      this.spinning = true;
      const response = await selectCanReceiveCouponList();
      if (response.code === 200) {
        this.receiveCouponList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    //获取用户已领取的优惠券 select === '2'
    async selectReceiveCoupon() {
      this.spinning = true;
      const response = await selectReceiveCouponList(this.tabsVal);
      if (response.code === 200) {
        this.receiveCoupon = response.data;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    handleCancel(){
      let flag = false;
      this.$emit("closeMainGiftCard", flag); //子组件向父组件传值（开关）
    },
    openUrl(url){
      window.open(url);
    },
    callback(key) {
      this.selectReceiveCoupon();
    },
    openGift(key) {
      this.tabsVal = 'no_use';
      this.select = key;
      if(this.select === '1'){
        this.selectCanReceiveCoupon();
      }else if(this.select === '2'){
        this.selectReceiveCoupon();
      }
    },
  }
}
</script>

<style scoped lang="scss">
.GiftCard{
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
  .GiftCard-view{
    width: 100%;
    .GiftCard-view-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -10px;
      //margin-bottom: 15px;
    }
    .title{
      width: 100%;
    }
    .titleImg {
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      .img {
        width: 280px;
      }

      .title-coupon-div {
        position: absolute;
        left: 0;
        top: 5px;
        display: flex;
        color: white;
        z-index: 9;

        .couponMoneyIcon {
          font-size: 20px;
          font-weight: bold;
        }

        .money {
          font-size: 30px;
          font-weight: bold;
        }

        .mark {
          //margin-bottom: 5px;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .markSY{
          font-size: 10px;
          font-weight: bold;
          margin-top: 5px;
        }
        .coupon-right-title {
          font-size: 16px;
          //margin-bottom: 5px;
        }

        .coupon-right-time {
          margin-bottom: 2px;
          font-size: 10px;
          font-weight: bold;
        }

        .coupon-right-tag {
          width: 100%;
          padding-left: 5px;

          .tag-font {
            font-size: 10px;
            font-weight: 400;
          }
        }
        .getGift{
          width: 85%;
          margin: auto;
          border-radius: 2px;
          height: 18px;
          cursor: pointer;
          line-height: 18px;
          font-weight: bold;
          color: red;
          font-size: 10px;
          background: linear-gradient(to right, #FFE9D4, #FFD2A9); /* 从左到右的渐变 */
        }
      }
    }
  }
}
</style>
