//认证与账号管理
import {
    get,
    del,
    post,
    put,
    getExel,
    postWorld,
    getById,
    postById,
    putById,
    delBiId,
    postByIdChannel
} from "@/service/base";

/**
 * 获取账号信息
 * @returns {Promise<* | void>}
 */
export function getAuthenticationInformation(){
    return get("/api/admin/authenticationInformation");
}

/**
 * 获取系统版本信息
 * @returns {Promise<* | void>}
 */
export function getYCompanyService(){
    return getById("/api/admin/getYCompanyService");
}

/**
 * 回显提交认证信息
 * @param data
 * @returns {Promise<* | void>}
 */
export function getSubmitCertification(data){
    return get("/api/admin/submitCertification",data);
}

/**
 * 查询税号是否重复
 * @param data
 * @returns {Promise<* | void>}
 */
export function dutyParagraphIsRepeat(data){
    return get("/api/admin/dutyParagraphIsRepeat",data);
}
/**
 * 账号信息-修改信息
 * @returns {Promise<* | void>}
 */
export function updAuthenticationInFo(data){
    return put("/api/admin/updAuthenticationInFo",data);
}

/**
 * 提交认证
 * @param data
 * @returns {Promise<unknown>}
 */
export function submitCertification(data){
    return post("/api/admin/submitCertification",data);
}

/**
 * 发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function sendSMS(data){
    return post("/api/admin/sendSMS",data);
}

/**
 * 选择首页模板
 * @param data  tempId 模板id  conferenceCode 会议编码
 * @returns {Promise<* | void>}
 */
export function selectConventionTemp(tempId,conferenceCode,data){
    return post("/api/admin/selectConventionTemp?tempId="+tempId+'&conferenceCode='+conferenceCode,data);
}

/**
 * 下载企业授权书
 * @param data
 * @returns {Promise<unknown>}
 */
export function getPowerOfAttorney(data){
    return postWorld("/api/admin/getPowerOfAttorney",data);
}

/**
 * 获取订购服务列表
 * @returns {Promise<unknown>}
 */
export function subscriptionServices(){
    return get("/api/admin/subscriptionServices");
}

/**
 * 提交订单
 * @returns {Promise<unknown>}
 */
export function postOrder(data){
    return post("/api/admin/order",data);
}

/**
 * 获取账号未付款的订单信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function unpaidOrder(){
    return get("/api/admin/unpaidOrder");
}

/**
 * 取消订单
 * @param data
 * @returns {Promise<unknown>}
 */
export function cancellationOrder(data){
    return put("/api/admin/cancellationOrder",data);
}

/**
 * 上传银行回执单
 * @param data
 * @returns {Promise<unknown>}
 */
export function uploadBankReceipt(data){
    return put("/api/admin/uploadBankReceipt",data);
}

/**
 * 获取订单列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function getOrder(data){
    return get("/api/admin/order",data);
}

/**
 * 删除订单
 * @param data
 * @returns {Promise<unknown>}
 */
export function delOrder(id){
    return del("/api/admin/order/"+id);
}

/**
 * 获取可合并开票订单列表
 * @returns {Promise<unknown>}
 */
export function paidOrder(){
    return get("/api/admin/paidOrder");
}

/**
 * 获取发票抬头信息
 * @returns {Promise<unknown>}
 */
export function invoiceHeader(){
    return get("/api/admin/invoiceHeader");
}

/**
 * 修改公司信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function updatebyid(data){
    return put("/api/company/updatebyid",data);
}

/**
 * 提交发票申请
 * @param data
 * @returns {Promise<unknown>}
 */
export function postInvoice(data){
    return post("/api/admin/invoice",data);
}

/**
 * 获取当前账号公司下所有发票信息
 * @param data
 * @returns {Promise<unknown>}
 */
export function getInvoice(data){
    return get("/api/admin/invoice",data);
}

/**
 * 查询用户的专属客服的二维码
 * @returns {Promise<unknown>}
 */
export function exclusiveKFImage(){
    return getById("/api/services/exclusiveKFImage");
}
/**
 * 查询用户的的二维码
 * @returns {Promise<unknown>}
 */
export function getKFImage(){
    return getById("/api/company/getKFImage");
}
/**
 * 查询用户是否领取过免费搭建微网站服务
 * @returns {Promise<unknown>}
 */
export function isLqMfFw(){
    return getById("/api/services/isLqMfFw");
}
/**
 * 查询用户是否有正在进行中的服务
 * @returns {Promise<unknown>}
 */
export function isHaveNoComService(){
    return getById("/api/services/isHaveNoComService");
}

/**
 * 获取服务介绍
 * @returns {Promise<unknown>}
 */
export function getServiceInfo(serviceCode){
    return getById("/api/services/getServiceInfo/"+serviceCode);
}
/**
 * 获取服务评价
 * @returns {Promise<unknown>}
 */
export function getServicePingJia(serviceCode){
    return getById("/api/services/getServicePingJia/"+serviceCode);
}

/**
 * 领取服务
 * @returns {Promise<unknown>}
 */
export function receiveService(data){
    return postById("/api/services/receiveService",data);
}

/**
 * 获取服务咨询列表
 * @returns {Promise<unknown>}
 */
export function serviceConsultList(data){
    return getById("/api/services/serviceConsultList",data);
}
/**
 * 删除服务咨询记录
 * @returns {Promise<unknown>}
 */
export function delServiceConsultList(id){
    return delBiId("/api/services/deleteById/"+id);
}

/**
 * 根据服务咨询id查询服务记录
 * @returns {Promise<unknown>}
 */
export function selectRecordList(id,data){
    return getById("/api/services/selectRecordList/"+id,data);
}

/**
 * 用户填写服务评价
 * @returns {Promise<unknown>}
 */
export function evaluateService(data){
    return postById("/api/services/evaluateService",data);
}

/**
 * 根据订单号取消订单
 * @returns {Promise<unknown>}
 */
export function cancellationByOrderNumber(data){
    return putById("/api/admin/cancellationByOrderNumber",data);
}


/**
 * 发布会议
 * @returns {Promise<unknown>}
 */
export function publishedConvention(conventionCode){
    return putById("/api/admin/publishedConvention/"+conventionCode);
}

/**
 * 获取单个服务评价
 * @returns {Promise<unknown>}
 */
export function selectByConId(id){
    return getById("/api/services/selectByConId/"+id);
}
/**
 * 账号有没有认证
 * @returns {Promise<unknown>}
 */
export function isHaveCompany(){
    return getById("/api/admin/isHaveCompany");
}
/**
 * 添加商品浏览记录
 * @returns {Promise<unknown>}
 */
export function addReportPb(data){
    return postById("/api/admin/addReportPb",data);
}
/**
 * 通知公告标记已读
 * @returns {Promise<unknown>}
 */
export function postSignRead(data){
    return postById("/system/notification/signRead",data);
}

/**
 * 获取系统通知未读条数
 * @returns {Promise<unknown>}
 */
export function selectNotReadAllCount(){
    return getById("/system/notification/selectNotReadAllCount");
}

/**
 * 获取用户未标记已读的通知公告
 * @returns {Promise<unknown>}
 */
export function selectNotReadAll(data){
    return getById("/system/notification/selectNotReadAll",data);
}

/**
 * 获取全部开启的系统公告列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function selectReadAll(data){
    return getById("/system/notification/selectOpenAll",data);
}


/**
 * 通过活动优惠券id领取优惠券
 * @param data
 * @returns {Promise<unknown>}
 */
export function postReceiveByDacId(data){
    return postByIdChannel("/coupon/receiveByDacId",data);
}


/**
 * 获取用户可领取的优惠券
 * @returns {Promise<unknown>}
 */
export function selectCanReceiveCouponList(){
    return getById("/coupon/selectCanReceiveCoupon");
}


/**
 * 获取用户可领取的优惠券
 * @returns {Promise<unknown>}
 * @param type   未使用传no_use 已使用传use 已过期传overdue
 */
export function selectReceiveCouponList(type){
    return getById("/coupon/selectReceiveCoupon/"+type);
}

/**
 * 用户获取优惠活动
 * @returns {Promise<unknown>}
 */
export function selectDiscountedActivities(){
    return getById("/coupon/selectDiscountedActivities");
}


/**
 * 通过活动id一键领取优惠券
 * @param data
 * @returns {Promise<unknown>}
 */
export function oneClickReceiveByDaId(data){
    return postById("/coupon/oneClickReceiveByDaId",data);
}
