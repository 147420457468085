<template>
  <div id="app">
    <!--    设置中文  -->
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
    <div></div>
  </div>
</template>

<script>
import { setXToken } from "@/service/base";
import { setById } from "@/service/base_y";
import { setUserId } from "@/service/common";

import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  data() {
    return {
      //设置中文
      zh_CN,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollMethod, true);
  },
  async created() {
    await this.setFrontRequest();
  },
  watch:{
    //监听路由
    $route(to, from){
      if(this.$store.state.scrollEl.type){ //判断位置进行变化后，将每个页面位置置零
        this.$store.state.scrollEl.target.scrollTop = 0;
      }
    }
  },
  methods: {
    scrollMethod(e) {
      this.$store.dispatch("scrollEl", e);
    },
    //向请求中添加请求头与公共请求参数
    async setFrontRequest() {
      //1.获取本地存储内容
      const user_info = window.localStorage.getItem("yichi_userInfo");
      this.$store.dispatch("userInfoList", JSON.parse(user_info));
      const id = JSON.parse(user_info).id;
      const token = JSON.parse(user_info).token;
      const type = JSON.parse(user_info).type;
      // console.log('id',id)
      //赋值x-token
      await setXToken(id, token, type);
      await setById(id,token);
      //赋值埋点id
      await setUserId(id);
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
