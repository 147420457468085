import axios from "axios";
import qs from "qs";
import headerConfig from "./api_header_config";
//玉松本地接口测试-冯


const header = headerConfig.api_header;
// const header='http://192.168.31.151:55555' //玉松本地
// const header = "http://192.168.31.194:55555"; // 尊贞本地
// const header = "http://admin-dev-api.yunicu.com";
// const header = "http://192.168.31.160:10086"; // 韩笑本地
// const header = "http://192.168.31.160:55555"; // 韩笑本地

let byId;
let token;

export function setById(by_Id,by_token) {
    byId = by_Id;
    token = by_token
}
//封装get
export function get(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: { "x-token": byId },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

//下载表格----params.ExelName为表格名字
export function getExel(url, params) {
  return axios
    .get(header + url, {
      headers: { byId: byId },
      params,
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf8",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = params.ExelName; //下载后文件名
      // downloadElement.download =decodeURIComponent(
      //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}
export function post(url, params) {
  return axios
    .post(header + url, params, { headers: { byId: byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postUserId(url, params) {
  return axios
    .post(header + url, params, { headers: { userId: byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postXtoken(url, params) {
  return axios
    .post(header + url, params, { headers: { "x-token": byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postToken(url, params) {
  return axios
    .post(header + url, params, { headers: { "token": token } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function put(url, params) {
  return axios
    .put(header + url, params, { headers: { byId: byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function putUserId(url, params) {
  return axios
    .put(header + url, params, { headers: { userId: byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function putXtoken(url, params) {
  return axios
    .put(header + url, params, { headers: { "x-token": byId } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function del(url, params) {
  return axios
    .delete(header + url, {
      headers: { byId: byId },
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function delUserId(url, params) {
  return axios
    .delete(header + url, {
      headers: { userId: byId },
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function delXtoken(url, params) {
  return axios
    .delete(header + url, {
      headers: { "x-token": byId },
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
