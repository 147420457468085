/**
 * name:fmx
 * data:2021年12月16日
 */
import{get,put,post,del,postById}from"./base";

//添加图片
export function postDocumentImg(File){
    return post('/api/admin/common/update',File,{headers:{'Content-Type':'multipart/form-data'}})
}
//批量上传图片
export function postImgList(file){
    return post('/api/admin/common/updates',file,{headers:{'Content-Type':'multipart/form-data'}})
}
//添加文件
export function postDocumentFile(File){
    return post('/api/admin/common/updateFile',File, {headers: {'Content-Type':'multipart/form-data'}})
}
//添加视频
export function postVideoFile(File){
    return post('/api/admin/uploadVideo',File, {headers: {'Content-Type':'multipart/form-data'}})
}
// export function updCompanyPayConfig (data) {
//     return postById('/api/admin/common/updCompanyPayConfig', data)
//   }

  export function updCompanyPayConfig(File){
    return postById('/api/admin/common/updCompanyPayConfig',File, {headers: {'Content-Type':'multipart/form-data'}})
}
