/**
 *
 * @param file
 * @returns {Promise<*>}
 */
import {postDocumentFile, postDocumentImg, postImgList, postVideoFile,updCompanyPayConfig} from "../service/document_api";

export async function update(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentImg(formData);
}
export async function updates(fileList) {
    let formData = new FormData()
    for (const file of fileList) {
        formData.append('file', file);
    }
    return  await postImgList(formData);
}
export async function update_File(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postDocumentFile(formData);
}
//上传视频
export async function update_Video(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await postVideoFile(formData);
}
// 上传商户私钥文件
export async function update_CompanyPayFile(file) {
    let formData = new FormData()
    formData.append('file', file)
    return  await updCompanyPayConfig(formData);
}
