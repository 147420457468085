// 正式环境
 const api_header = "https://api.yichimeeting.com";
// 正式-商户网址
const webLink = "https://console.yichimeeting.com/meetingManagement";

// 测试环境
//  const api_header = "https://api-dev.yichimeeting.com";
//测试-商户网址
// const webLink = "https://console-dev.yichimeeting.com/meetingManagement";


//本地环境
// const api_header = "http://b3exjm.natappfree.cc";
// const api_header = "http://192.168.1.92:10011";
//  const webLink = "http://192.168.1.166:8080/meetingManagement";

export default {
  api_header,
  webLink,
};
