//公共的api接口
import {post, postLogin, put, putSetPassWord} from "@/service/base";
import qs from "qs";
let user_id;

export function setUserId(value){
    user_id = value
}

/**
 * 上报埋点数据
 */
export function auth_data_report(modular,module_function,content_id){
    return post("/api/admin/auth/auth_data_report",{"user_id":user_id,modular,module_function,content_id})
}

/**
 * 登录
 */
export function login(data){
    return postLogin("/api/admin/adminLogin",data)
}
/**
 * 验证码登录-获取验证码
 * @returns {Promise<unknown | void>}
 */
export function postLoginPhoneCode (data) {
    return post('/api/admin/adminLoginSendSMS', data)
}

/**
 * 验证码登录
 * @param data
 * @returns {Promise<unknown>}
 */
export function postLoginPhoneCodeSubmit (data) {
    return post('/api/admin/loginByTelSMS', data)
}

/**
 * 判断验证码是否正确
 * @param data
 * @returns {Promise<* | void>}
 */
export function adminForgetPassWordSMS (data) {
    return post('/api/admin/adminForgetPassWordSMS', data)
}

/**
 * 修改登录密码
 * @param data
 * @returns {Promise<unknown>}
 */
export function updLoginPassWord (data) {
    return putSetPassWord('/api/admin/updLoginPassWord', data)
}

/**
 * 注册时发送验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export function adminRegisterSendSMS (data) {
    return post('/api/admin/adminRegisterSendSMS', data)
}

/**
 * 注册
 * @param data
 * @returns {Promise<unknown>}
 */
export function adminLoginRegister (data) {
    return post('/api/admin/adminLoginRegister', data)
}
