<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      style="background-color: #fff"
      class="sider"
      breakpoint="xl"
    >
      <!--   管理员菜单   -->
<!--      <administrator-sider v-if="isStaff === 2" :collapsed="collapsed" />-->
      <!--   商户后台菜单   -->
<!--      <manage-sider v-else :collapsed="collapsed" />-->
      <manage-sider :collapsed="collapsed" />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; height: auto">
      
        <div style="display: flex; height: 64px">
          <!--        <div class="logo" v-if="!collapsed">-->
          <!--            <span @click="() => {-->
          <!--            this.$router.push('/photoSuperAdmin');-->
          <!--            this.select = '[0]';-->
          <!--          }" style="cursor: pointer">-->
          <!--              <img src="@/assets/yich_logo.png" style="width: 90px;" />-->
          <!--            </span>-->
          <!--        </div>-->
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <ManageHeader />
        </div>
      </a-layout-header>
      <a-layout-content
        style="background: #edeff2; overflow-y: scroll; position: relative;"
      >
        <div
          class="content-top"
          v-show="isShowNav"
        
          v-bind:style="{ position: 'fixed', top: '64px',zIndex:zIndex }"
        >
        <!-- z-index:9;  (登录进来会闪动)-->
        <!-- style="position: fixed; top: 64px;z-index:0" -->
          <span>
            <span style="color: #a0a2a3">{{ manageHeaderTitleFTitle }}</span>
            <span style="color: rgba(0, 0, 0, 0.85)">{{
              " / " + manageHeaderTitleSTitle
            }}</span>
          </span>
        </div>
        <div style="height: 50px" v-show="isShowNav"></div>
        <div>
          <router-view
            style="background-color: #fff; min-height: 850px"
          ></router-view>
          <div v-if="isStaff !== 2">
            <div class="rightXF">
              <div v-show="iconShow">
                <a-popover placement="leftBottom">
                  <template slot="content">
<!--                    <p style="color: #b3b3b3">客服电话：4008-554-120</p>-->
                    <img v-if="kefuImg" style="height: 200px" :src="kefuImg" alt=""/>
                    <img v-else style="width: 200px" src="../assets/login/consultant.png" alt=""/>
                  </template>
                  <div class="consult">
                    <div style="font-size: 22px; border: 1px dashed #f5f5f5">
                      <admin_con type="icon-zixunjilu" />
                    </div>
                    <div style="margin-top: -3px">咨询</div>
                  </div>
                </a-popover>
              </div>
              <div v-show="iconShow">
                <a-popover placement="left">
                  <template slot="content">
                    <div>感谢您的关注，我们期待您的建议{{kefuImg}}</div>
                    <div style="margin-top: 10px">投诉建议邮箱：wangxy@yichimeeting.com</div>
                  </template>
                  <div class="feedback">
                    <div style="font-size: 19px">
                      <admin_con type="icon-drxx64" />
                    </div>
                    <div style="margin-top: 1px">反馈</div>
                  </div>
                </a-popover>
              </div>
              <div v-show="iconShow">
                <a-popover placement="leftBottom">
                  <template slot="content">
                    <div style="width: 100%; text-align: center">
                      <img
                      style="width: 200px"
                      src="../assets/login/code.png"
                      alt=""
                    />
                      <div>微信扫一扫</div>
                      <div>关注医驰会务官方公众号</div>
                    </div>
                  </template>
                  <div class="QRCode">
                    <div style="font-size: 28px">
                      <admin_con type="icon-erweima1" />
                    </div>
                  </div>
                </a-popover>
              </div>
              <div @click="icon">
                <div class="jiantuo">
                  <div style="font-size: 27px; margin-left: 3px">
                    <admin_con
                      type="icon-xiangyoushuangjiantou"
                      v-if="iconShow"
                    />
                    <admin_con type="icon-xiangzuoshuangjiantou" v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import ManageSider from "@/components/ManageMenu";
import ManageHeader from "@/components/ManageHeader";
import ManageFooter from "@/components/ManageFooter";
import AdministratorSider from "@/components/adminMenuManage";
import { admin_icon } from "@/utils/myIcon";
import { getKFImage} from "@/service/authentication";

export default {
  name: "ManageRoot",
  components: {
    ManageFooter,
    ManageHeader,
    ManageSider,
    AdministratorSider,
    admin_con: admin_icon,
  },
  watch: {
  //此时我监听的是对象，当$store.state.userInfo.Name发生修改时，此时需要深度监听才能监听到数据变化
  "$store.state.isOpenVoucherModel":{
    deep:true,//深度监听设置为 true  isOpenVoucherModel:false,
    handler:function(newVal,oldVal){
      console.log("数据发生变化啦"); //修改数据时，能看到输出结果
      // console.log(newVal,oldVal);
      if(newVal === false){
        // console.log("关闭了")
        this.zIndex = 9
      }else{
        this.zIndex = 0
      }
    }
  }
},
  data() {
    return {
      collapsed: false,
      nav: {},
      isStaff: null, //身份
      iconShow: true,
      zIndex:9
    };
  },
  created() {
    let isStaff = localStorage.getItem("yichi_userInfo");
    this.isStaff = JSON.parse(isStaff).isStaff;
    this.getKFImage();
  },
  methods: {
    //获取客服二维码,并保存vuex中，其他地方获取到二维码直接替换掉当前客服二维码
    async getKFImage() {
      const response = await getKFImage();
      if (response.code === 200) {
        this.$store.dispatch("kefuImg", response.data); //客服二维码
      } else {
        this.$message.warning(response.message);
      }
    },
    icon() {
      this.iconShow = !this.iconShow;
    },
  },
  computed: {
    manageHeaderTitleFTitle() {
      return this.$store.state.manageHeaderTitleFTitle;
    },
    manageHeaderTitleSTitle() {
      return this.$store.state.manageHeaderTitleSTitle;
    },
    isShowNav() {
      return this.$store.state.isShowNav;
    },
    kefuImg() {
      return this.$store.state.kefuImg;
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0;
}
::-webkit-scrollbar-track {
  background-color: #edeff2;
}
.ant-layout {
  height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  border-left: 1px solid #f5f5f5;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

/*#components-layout-demo-custom-trigger .logo {*/
/*  height: 32px;*/
/*  background: rgba(255, 255, 255, 0.2);*/
/*  margin: 16px;*/
/*}*/
.logo {
  width: 193px;
  /*width: 217px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.sider {
  overflow-x: hidden;
  overflow-y: scroll;
}
.content-top {
  background-color: #fff;
  height: 50px;
  width: 100%;
  line-height: 50px;
  padding-left: 1.4%;
  font-size: 15px;
  border-top: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
}

.rightXF {
  position: fixed;
  right: 20px;
  bottom: 5%;
  z-index: 99999;
  .consult {
    width: 36px;
    text-align: center;
    height: 55px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 15px #d7d7d7;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.85);
    margin-bottom: 10px;
    .img {
      width: 60px;

    }
  }
  .feedback {
    width: 36px;
    text-align: center;
    height: 55px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 15px #d7d7d7;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.85);
    margin-bottom: 10px;
  }
  .QRCode {
    width: 36px;
    text-align: center;
    height: 45px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 15px #d7d7d7;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.85);
    margin-bottom: 10px;
  }
  .jiantuo {
    width: 36px;
    text-align: center;
    height: 42px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 15px #d7d7d7;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.85);
  }
}
</style>
